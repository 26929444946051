// import { useRouter } from 'next/router';
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Collapse } from "react-collapse";
import imgprofilo from "../assets/imgprofilo.png";
import noPic from "../assets/noPic.webp";
// import Meta from '../utils/Meta';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/loader";
import ModaleDelete from "../components/modal";
import {
  GetProjects,
  projectsState,
  LogoutCache,
} from "../utils/atom/projects";
import { useRecoilState } from "recoil";

// import ModalOtp from "../components/modalOtp";
export default function Profile({ cookies }) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  async function getUser() {
    const user = cookies.get("user");
    const token = cookies.get("jwt");
    if (!token) {
      navigate("/login");
    }
    try {
      const qs = require("qs");
      const query = qs.stringify(
        {
          populate: ["immagine"],
        },
        { encode: false }
      );
      const res = await fetch(
        process.env.REACT_APP_URL_BACKEND + "users/me?" + query,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      const res_ = data;
      if (res_ && res_.user.id) {
        setUser(res_.user);
        setModifyUser(res_.user);
        cookies.set("user", res_.user);
        if (res_ && res_.accounts) {
          setAccountGoogle(res_.accounts);
        }
      } else {
        notify("Error loading user");
        // navigate("/login");
      }
    } catch {
      navigate("/login");
    } finally {
      setLoader(false);
    }
  }
  const [accountGoogle, setAccountGoogle] = useState([]);

  const notify = (message, success) =>
    success
      ? toast.success(message, {
          position: "top-right",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      : toast.error(message, {
          position: "top-right",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

  const [user, setUser] = useState({});
  const [modifyUser, setModifyUser] = useState({});
  const [password, setPassword] = useState({
    currentPassword: null,
    password: null,
    passwordConfirmation: null,
  });
  const router = useParams();
  useEffect(() => {
    getUser();
  }, []);

  const [disableInput, setDisabledInput] = useState(false);
  // changhe password
  async function changePassword() {
    const token = cookies.get("jwt");
    //check if password and passwordConfirmation are the same and exist
    if (password.password !== password.passwordConfirmation) {
      notify("The passwords do not match");
      return;
    }
    if (
      !password.currentPassword ||
      !password.password ||
      !password.passwordConfirmation
    ) {
      notify("Enter all passwords");
      return;
    }
    try {
      setDisabledInput(true);
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND + `auth/change-password`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Specify content type as JSON
          },
          body: JSON.stringify({
            currentPassword: password.currentPassword,
            password: password.password,
            passwordConfirmation: password.passwordConfirmation,
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .catch((error) => {});
      if (response.user && response.jwt) {
        cookies.set("user", response.user);
        // localStorage.setItem("user", JSON.stringify(response.user));
        const jwtToken = response.jwt;
        cookies.set("jwt", jwtToken);
        // localStorage.setItem("jwt", jwtToken);
        setPassword({
          currentPassword: null,
          password: null,
          passwordConfirmation: null,
        });
        getProfileData();
        setProfile(3);
        notify("Password changed successfully", true);
      } else {
        if (
          response?.error?.status === 400 &&
          response?.error?.message ===
            "The provided current password is invalid"
        ) {
          notify("Current Password is incorrect");
          return;
        }
        notify("Error changing password, please try again");
      }
    } catch {
      notify("Error changing password, please try again");
    } finally {
      setDisabledInput(false);
    }
  }

  async function updateImage(e) {
    // setLoaderGeneric(true)
    const token = cookies.get("jwt");
    const formData = new FormData();
    formData.append("ref", "plugin::users-permissions.user");
    formData.append("refId", user.id);
    formData.append("field", "immagine");
    formData.append("files", e.target.files[0]);
    try {
      setDisabledInput(true);
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND + `upload/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      )
        .then((response) => {
          return response;
        })
        .catch((error) => {});
      // setLoaderGeneric(false);
      if (response.status && response?.status === 200) {
        getProfileData();
        notify("Image successfully inserted", true);
      } else {
        notify("Error inserting image, please try again");
      }
      // remove targer file on
      e.target.value = null;
    } catch {
      notify("Error inserting image, please try again");
    } finally {
      setDisabledInput(false);
    }
  }
  async function getProfileData() {
    const token = cookies.get("jwt");
    const qs = require("qs");
    const query = qs.stringify(
      {
        populate: ["immagine"],
      },
      { encode: false }
    );
    try {
      setDisabledInput(true);
      const res = await fetch(
        process.env.REACT_APP_URL_BACKEND + "users/me?" + query,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      const res_ = data;
      if (res_ && res_.user.id) {
        cookies.remove("refresh");
        setClick(false);
        setUser(res_.user);
        setModifyUser(res_.user);
        cookies.set("user", res_.user);
        if (res_ && res_.accounts) {
          setAccountGoogle(res_.accounts);
        }
      } else {
        notify("Error loading user");
        // navigate("/login");
      }
    } catch (error) {
      notify("Errore nel caricamento dei dati, riprova");
    } finally {
      setDisabledInput(false);
    }
  }

  async function removePicture() {
    const token = cookies.get("jwt");
    try {
      setDisabledInput(true);
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND +
          `upload/files/${user?.immagine?.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          return response;
        })
        .catch((error) => {});
      if (response.status && response?.status === 200) {
        getProfileData();
        notify("Image successfully removed", true);
      } else {
        notify("Error removing image, please try again");
      }
    } catch (error) {
      notify("Error removing image, please try again");
    } finally {
      setDisabledInput(false);
    }
  }
  const [profile, setProfile] = useState(0);

  function isValidEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  //change DataUser
  async function changeDataUser() {
    const checkEmail = isValidEmail(modifyUser.email);
    if (!modifyUser.email || !checkEmail) {
      notify("Enter a valid email");
      return;
    }

    const token = cookies.get("jwt");
    try {
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND + `users/${user.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Specify content type as JSON
          },
          body: JSON.stringify({
            given_name: modifyUser.given_name,
            family_name: modifyUser.family_name,
            company: modifyUser.company,
            role_company: modifyUser.role_company,
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .catch((error) => {});
      if (response.id) {
        getProfileData();
        setProfile(0);
        notify("Data modified successfully", true);
      } else {
        if (
          response?.error?.status === 400 &&
          response?.error?.message === "Email already taken"
        ) {
          notify("Email already in use by another user");
        } else {
          notify("Error while editing data, please try again");
        }
      }
    } catch (error) {
      notify("Error while editing data, please try again");
    }
  }
  async function removeAccountGoogle(id) {
    const token = cookies.get("jwt");
    try {
      const account = accountGoogle.find((account) => account.id === id);
      if (!account) {
        notify("Error removing account, please try again");
        return;
      }
      const accounts = accountGoogle.filter((account) => account.id !== id);
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND + `users/${user.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Specify content type as JSON
          },
          body: JSON.stringify({
            accounts: accounts?.map((account) => account.id),
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .catch((error) => {});
      if (response.id) {
        getProfileData();
        setProfile(1);
        notify("Account successfully removed", true);
      } else {
        if (response?.error?.message) {
          notify(response?.error?.message);
        } else if (
          response?.error?.status === 400 &&
          response?.error?.message === "Email already taken"
        ) {
          notify("Email already in use by another user");
        } else {
          notify("Error while editing data, please try again");
        }
      }
    } catch (error) {
      notify("Error while editing data, please try again");
    }
  }

  const [statePassword, setStatePassword] = useState({
    currentPassword: true,
    password: true,
    passwordConfirmation: true,
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);

  async function deleteUser() {
    setDisabledButton(true);
    try {
      const token = cookies.get("jwt");
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND + `users/${user.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Specify content type as JSON
          },
        }
      )
        .then((response) => {
          return response;
        })
        .catch((error) => {});
      if (response.status && response?.status === 200) {
        notify("Account successfully deleted", true);
        setTimeout(() => {
          // localStorage.removeItem("user");
          // localStorage.removeItem("jwt");
          cookies.remove("user");
          cookies.remove("jwt");
          navigate("/login");
        }, 3000);
      } else {
        notify("Error removing account, please try again");
      }
    } catch (error) {
      notify("Error removing account, please try again");
    } finally {
      setDisabledButton(false);
    }
  }
  const [disabledButton, setDisabledButton] = useState(false);

  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState(0);
  function getColor() {
    if (passwordStrength === 0) {
      return "white";
    } else if (passwordStrength === 4) {
      return "green";
    } else if (passwordStrength >= 2) {
      return "yellow";
    } else {
      return "red";
    }
  }
  function handleInput(event) {
    const { value, name } = event.target;

    setPassword((prevNewUser) => ({
      ...prevNewUser,
      [name]: value,
    }));
    if (name === "password") {
      const validationResult = validatePasswordB(value);
      setIsPasswordValid(validationResult.isValid);
      setPasswordErrors(validationResult.errors);
      setPasswordStrength(validationResult.strength);
      if (password.passwordConfirmation !== value) {
        setPasswordIsEqual(false);
        setDisabledButton(true);
        return;
      } else {
        setDisabledButton(false);
        setPasswordIsEqual(true);
      }
    }
    if (name === "passwordConfirmation") {
      // check if password is the same
      if (password.password !== value) {
        setPasswordIsEqual(false);
        setDisabledButton(true);
        return;
      } else {
        setDisabledButton(false);
        setPasswordIsEqual(true);
      }
    }
    setDisabledInput(false);
  }

  const [passwordIsEqual, setPasswordIsEqual] = useState(true);

  function validatePasswordB(password) {
    // const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!?@#$%^&*()_+.]).{6,}$/;
    const errors = {};
    let strength = 0;

    if (password.length < 8) {
      errors.lengthError = true;
    } else {
      strength += 1;
    }
    if (/(?=.*[A-Z])/.test(password)) {
      strength += 1;
    } else {
      errors.uppercaseError = true;
    }
    if (/(?=.*\d)/.test(password)) {
      strength += 1;
    } else {
      errors.numberError = true;
    }
    if (/(?=.*[!?@#$%^&*()_+])/.test(password)) {
      strength += 1;
    } else {
      errors.specialCharError = true;
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors: errors,
      strength: strength,
    };
  }

  const [isOpenC, setIsOpenC] = useState(false);

  const handleImageErrorSelected = (event) => {
    event.target.src = imgprofilo;
  };

  const LoginFunction = async () => {
    // Ottieni l'URL dall'operazione asincrona
    const url = await AuthLocal();
    setClick(true);
    // Apri la pagina con il ritardo minimo
    openPage(url);
  };

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Apri una pagina in una finestra separata.
   * @param {string} url URL della pagina da aprire.
   * @description
   * La funzione utilizza setTimeout per garantire che la finestra venga aperta in modo sincrono.
   * Se l'URL   valido, la pagina viene aperta in una finestra separata.
   * Se l'URL non   valido, viene stampato un errore in console.
   */
  /******  a1474af5-93be-470b-a8c4-9157b861ee05  *******/
  const openPage = (url) => {
    // Usa setTimeout per garantire che la finestra venga aperta in modo sincrono
    setTimeout(() => {
      if (url) {
        window.open(url, "_blank");
      } else {
        console.error("URL non valido.");
      }
    }, 0); // Ritardo minimo
  };

  const AuthLocal = async () => {
    const url = process.env.REACT_APP_URL_BACKEND + "auth-google";
    try {
      const token = cookies.get("jwt");
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      if (data.client && data.client.url) {
        return data.client.url;
      }
      return null; // Nessun URL trovato
    } catch (error) {
      console.error("Error fetching data:", error);
      return null; // Gestisci l'errore, nessun URL
    }
  };
  const [click, setClick] = useState(false);
  const [projects, setProjects] = useRecoilState(projectsState);
  useEffect(() => {
    let intervalId;
    intervalId = setInterval(() => {
      const refresh = cookies.get("refresh");
      if (click && refresh) {
        getProfileData();
        const token = cookies.get("jwt");
        // const p = GetProjects(true, projects, setProjects, token, cookies);
      }
    }, 100);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [click]);



  return (
    <div className="bg-white w-full max-w-full overflow-y-hidden   gap-5 px-3 md:px-16  text-[#161931]  min-h-screen pt-6">
      <div className="w-full flex items-center justify-between">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2"
        >
          Back to Dashboard
        </button>
      </div>
      <div className="flex flex-col lg:flex-row">
        {/* <Meta
                title="
           Profile"
            /> */}
        <ToastContainer />
        <aside className="py-4 w-full lg:w-1/4  pt-24 ">
          <div className=" flex flex-wrap flex-row lg:flex-col gap-2 p-4 text-sm lg:border-r border-gray-200 lg:top-28 items-center lg:items-start">
            <h2 className="lg:pl-3 lg:mb-4 text-2xl font-semibold hidden lg:block">
              Profilo
            </h2>
            <button
              onClick={() => {
                {
                  setProfile(0);
                  setPassword({
                    currentPassword: null,
                    password: null,
                    passwordConfirmation: null,
                  });
                }
              }}
              className={`flex items-center px-3 py-2.5 font-bold bg-white border ${
                profile === 0
                  ? "text-[#F9A257] border-gray-200"
                  : "text-black hover:border-gray-200 border-transparent"
              }  rounded-full`}
            >
              Profilo Utente
            </button>
            <button
              onClick={() => {
                {
                  getProfileData();
                  setProfile(1);
                }
              }}
              className={`flex items-center px-3 py-2.5 font-bold bg-white border ${
                profile === 1
                  ? "text-[#F9A257] border-gray-200"
                  : "text-black hover:hover:border-gray-200 border-transparent"
              }  rounded-full`}
            >
              Account Google
            </button>
            <button
              onClick={() => {
                {
                  setProfile(2);
                }
              }}
              className={`flex items-center px-3 py-2.5 font-bold bg-white border ${
                profile === 2
                  ? "text-[#F9A257] border-gray-200"
                  : "text-black hover:hover:border-gray-200 border-transparent"
              }  rounded-full`}
            >
              Change Password
            </button>
            <button
              onClick={() => {
                {
                  // send mail to webmaster@fortop.it
                  window.location.href = `mailto:
                                ${process.env.NEXT_PUBLIC_MAILTO}?subject=Segnalazione%20problema%20account%20${user?.email}&body=Descrivi%20il%20problema%20riscontrato%20nel%20tuo%20account%20${user?.email}`;
                }
              }}
              className={`flex items-center px-3 py-2.5 font-bold bg-white border text-black hover:hover:border-gray-200 border-transparent  rounded-full`}
            >
              Report a problem
            </button>
            {user.otp_verified && (
              <button
                onClick={() => {
                  setOpenOtp(true);
                }}
                className="flex items-center px-3 py-2.5 font-semibold  hover:text-black hover:border hover:rounded-full"
              >
                Disconnetti OTP
              </button>
            )}
            <button
              onClick={() => {
                setIsOpen(true);
              }}
              className="flex items-center px-3 py-2.5 font-semibold  hover:text-black hover:border hover:rounded-full"
            >
              Delete account
            </button>
            <button
              onClick={() => {
                cookies.remove("jwt");
                cookies.remove("user");
                window.location.href = "/login";
              }}
              className="flex items-center px-3 py-2.5 font-semibold  hover:text-black hover:border hover:rounded-full"
            >
              Logout
            </button>
          </div>
        </aside>
        {loader ? (
          <div className="w-full flex flex-col gap-5  text-[#161931]   justify-center items-center md:pt-10">
            <Loader />
          </div>
        ) : (
          <div className=" w-full flex flex-col gap-5  text-[#161931]   justify-center items-center md:pt-10">
            {profile !== 1 && (
              <div className="w-full py-1  ">
                <div className="flex  items-center flex-row justify-center gap-4 md:gap-8">
                  <img
                    className="object-cover lg:w-36 lg:h-36 h-28 w-28 p-1 rounded-full ring-2 ring-[#F9A257] "
                    src={
                      user?.immagine
                        ? process.env.REACT_APP_URL_BACKEND +
                          user?.immagine?.url
                        : noPic
                    }
                    alt="Picture User"
                  />
                  <div className="flex flex-col space-y-5 ">
                    <label
                      htmlFor="upload-photo"
                      className="md:px-5 px-2 py-1.5 md:py-2.5 text-base font-medium text-white focus:outline-none bg-black rounded-lg border border-black hover:border-[#F9A257] hover:text-[#F9A257] hover:bg-white cursor-pointer"
                    >
                      <span>Change Image</span>
                      <input
                        onChange={(e) => updateImage(e)}
                        accept="image/*"
                        id={"upload-photo"}
                        className="opacity-0 absolute z-[-1]"
                        type="file"
                        disabled={disableInput}
                      />
                    </label>
                    {user && user.immagine && (
                      <button
                        type="button"
                        onClick={() => removePicture()}
                        className="px-5 py-2.5 text-base font-medium text-black focus:outline-none bg-white rounded-lg border border-[#F9A257] hover:bg-gray-100 hover:text-[#202142] "
                      >
                        Delete Image
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="w-full py-1 md:w-1/2 px-4">
              {profile === 0 ? (
                <div className="w-full  sm:rounded-lg">
                  <div className="items-center mt-4 text-[#202142]">
                    <div className="flex flex-col items-center w-full gap-4 lg:flex-row   sm:mb-4">
                      <div className="w-full">
                        <label
                          htmlFor="first_name"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          id="first_name"
                          className="bg-transparent border border-gray-200 text-black outline-none text-sm rounded-lg ring-0 focus-visible:border-[#F9A257] focus:border-[#F9A257] block w-full p-2.5 "
                          placeholder="Name"
                          required
                          value={modifyUser && modifyUser.given_name}
                          onChange={(e) => {
                            setModifyUser({
                              ...modifyUser,
                              given_name: e.target.value,
                            });
                          }}
                          disabled={disableInput}
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="last_name"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Surname
                        </label>
                        <input
                          type="text"
                          id="last_name"
                          className="bg-transparent border border-gray-200 text-black outline-none text-sm rounded-lg focus-visible:border-[#F9A257] focus:border-[#F9A257] block w-full p-2.5 "
                          placeholder="Surname"
                          required
                          value={modifyUser && modifyUser.family_name}
                          onChange={(e) => {
                            setModifyUser({
                              ...modifyUser,
                              family_name: e.target.value,
                            });
                          }}
                          disabled={disableInput}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-full gap-4 lg:flex-row   sm:mb-4">
                      <div className="w-full">
                        <label
                          htmlFor="azienda"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Company
                        </label>
                        <input
                          type="text"
                          id="azienda"
                          className="bg-transparent border border-gray-200 text-black outline-none text-sm rounded-lg ring-0 focus-visible:border-[#F9A257] focus:border-[#F9A257] block w-full p-2.5 "
                          placeholder="Company"
                          required
                          value={modifyUser && modifyUser.company}
                          onChange={(e) => {
                            setModifyUser({
                              ...modifyUser,
                              company: e.target.value,
                            });
                          }}
                          disabled={disableInput}
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="ruolo_azienda"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Role Company
                        </label>
                        <input
                          type="text"
                          id="ruolo_azienda"
                          className="bg-transparent border border-gray-200 text-black outline-none text-sm rounded-lg focus-visible:border-[#F9A257] focus:border-[#F9A257] block w-full p-2.5 "
                          placeholder="Role Company"
                          required
                          value={modifyUser && modifyUser.role_company}
                          onChange={(e) => {
                            setModifyUser({
                              ...modifyUser,
                              role_company: e.target.value,
                            });
                          }}
                          disabled={disableInput}
                        />
                      </div>
                    </div>

                    <div className="mb-2 sm:mb-6">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-black"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        value={modifyUser && modifyUser.email}
                        onChange={(e) => {
                          setModifyUser({
                            ...modifyUser,
                            email: e.target.value,
                          });
                        }}
                        disabled={true}
                        className=" border border-gray-200 text-black text-sm rounded-lg 
                                             focus-visible:border-[#F9A257] focus:border-[#F9A257] block w-full p-2.5 outline-none"
                        placeholder="your@mail.com"
                      />
                    </div>
                    <div className="flex justify-end pb-4">
                      <button
                        type="submit"
                        onClick={() => changeDataUser()}
                        className="bg-[#F9A257] hover:bg-white hover:text-[#F9A257] border border-[#F9A257] text-white font-bold px-5 py-2.5 text-sm w-full sm:w-auto rounded focus:outline-none focus:shadow-outline"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : profile === 1 ? (
                <div>
                  <div className="w-full  sm:max-w-xl sm:rounded-lg flex flex-col gap-4">
                    <button
                      className="w-full flex items-center justify-center p-3 bg-white text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100
            font-semibold text-lg
            "
                      onClick={() => LoginFunction()}
                    >
                      <img
                        src="https://developers.google.com/identity/images/g-logo.png"
                        alt="Google Logo"
                        className="w-6 h-6 mr-2"
                      />
                      Login In with Google
                    </button>
                    {accountGoogle?.map((account, index) => (
                      <div className="border rounded-xl " key={index}>
                        <div
                          className="flex items-center cursor-pointer p-4 "
                          onClick={() => {
                            setIsOpenC(isOpenC === index ? null : index);
                          }}
                        >
                          <img
                            src={account.picture}
                            alt="profile"
                            className="w-6 h-6 rounded-full mr-2"
                            onError={handleImageErrorSelected}
                            referrerPolicy="no-referrer"
                          />
                          <div>
                            <h5 className="capitalize">{account.fullName}</h5>
                            {/* <span>{account.email}</span> */}
                          </div>
                        </div>
                        <Collapse isOpened={isOpenC === index}>
                          <div className="flex flex-col gap-2 mt-4 px-4 pb-4">
                            <h5>{account.email}</h5>
                            <button
                              className="rounded-md p-2
                bg-red-200
                hover:bg-red-300"
                              onClick={() => removeAccountGoogle(account.id)}
                            >
                              Disconnetti
                            </button>
                          </div>
                        </Collapse>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="w-full   sm:max-w-xl sm:rounded-lg">
                  <div className="grid max-w-2xl mx-auto ">
                    <div className="items-center mt-4 text-[#202142]">
                      <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-4">
                        <div className="w-full">
                          <label
                            htmlFor="currentPassword"
                            className="block mb-2 text-sm font-medium text-black"
                          >
                            Current Password
                          </label>
                          <div className="relative">
                            <input
                              type={
                                statePassword.currentPassword
                                  ? "password"
                                  : "text"
                              }
                              id="currentPassword"
                              className="bg-transparent border border-gray-200 text-black outline-none text-sm rounded-lg focus-visible:border-[#F9A257] focus:border-[#F9A257] block w-full p-2.5 "
                              placeholder="••••••••"
                              value={password.currentPassword}
                              onChange={(e) => {
                                handleInput(e);
                                // setPassword({ ...password, currentPassword: e.target.value })
                              }}
                              name="currentPassword"
                              disabled={disableInput}
                            />
                            <div className="absolute right-4 top-2.5 ">
                              {!statePassword.currentPassword ? (
                                <svg
                                  onClick={() =>
                                    setStatePassword({
                                      ...statePassword,
                                      currentPassword: true,
                                    })
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-eye-off"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                                  <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                                  <path d="M3 3l18 18" />
                                </svg>
                              ) : (
                                <svg
                                  onClick={() =>
                                    setStatePassword({
                                      ...statePassword,
                                      currentPassword: false,
                                    })
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-eye"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-4">
                        <div className="w-full">
                          <label
                            htmlFor="password"
                            className="block mb-2 text-sm font-medium text-black"
                          >
                            New Password
                          </label>
                          <div className="relative">
                            <input
                              type={
                                statePassword.password ? "password" : "text"
                              }
                              id="password"
                              className="bg-transparent border border-gray-200 text-black outline-none text-sm rounded-lg  focus-visible:border-[#F9A257] focus:border-[#F9A257] block w-full p-2.5 "
                              placeholder="••••••••"
                              value={password.password}
                              onChange={(e) => {
                                handleInput(e);
                                // setPassword({ ...password, password: e.target.value })
                              }}
                              name="password"
                              disabled={disableInput}
                            />
                            <div className="absolute right-4 top-2.5 ">
                              {!statePassword.password ? (
                                <svg
                                  onClick={() =>
                                    setStatePassword({
                                      ...statePassword,
                                      password: true,
                                    })
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-eye-off"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                                  <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                                  <path d="M3 3l18 18" />
                                </svg>
                              ) : (
                                <svg
                                  onClick={() =>
                                    setStatePassword({
                                      ...statePassword,
                                      password: false,
                                    })
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-eye"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-4">
                        <div className="w-full">
                          <label
                            htmlFor="passwordConfirmation"
                            className="block mb-2 text-sm font-medium text-black"
                          >
                            Confirm Password
                          </label>
                          <div className="relative">
                            <input
                              type={
                                statePassword.passwordConfirmation
                                  ? "password"
                                  : "text"
                              }
                              id="passwordConfirmation"
                              className="bg-transparent border border-gray-200 text-black outline-none text-sm rounded-lg focus-visible:border-[#F9A257] focus:border-[#F9A257]  block w-full p-2.5 "
                              placeholder="••••••••"
                              value={password.passwordConfirmation}
                              onChange={(e) => {
                                handleInput(e);
                                // setPassword({ ...password, passwordConfirmation: e.target.value })
                              }}
                              name="passwordConfirmation"
                              disabled={disableInput}
                            />
                            <div className="absolute right-4 top-2.5 ">
                              {!statePassword.passwordConfirmation ? (
                                <svg
                                  onClick={() =>
                                    setStatePassword({
                                      ...statePassword,
                                      passwordConfirmation: true,
                                    })
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-eye-off"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                                  <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                                  <path d="M3 3l18 18" />
                                </svg>
                              ) : (
                                <svg
                                  onClick={() =>
                                    setStatePassword({
                                      ...statePassword,
                                      passwordConfirmation: false,
                                    })
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-eye"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {!passwordIsEqual && (
                        <div className="text-red-500 text-xs">
                          The passwords do not match
                        </div>
                      )}
                      <div
                        className="w-full h-2 my-2 rounded-full"
                        style={{ backgroundColor: getColor() }}
                      ></div>
                      {!isPasswordValid && (
                        <div className="text-red-500 text-xs">
                          <p>The password must:</p>
                          <p
                            className={
                              passwordErrors.lengthError
                                ? `text-red-500`
                                : "text-green-500"
                            }
                          >
                            be at least 8 characters long.
                          </p>
                          <p
                            className={
                              passwordErrors.uppercaseError
                                ? `text-red-500`
                                : "text-green-500"
                            }
                          >
                            contain at least one capital letter.
                          </p>
                          <p
                            className={
                              passwordErrors.numberError
                                ? `text-red-500`
                                : "text-green-500"
                            }
                          >
                            contain at least one number.
                          </p>
                          <p
                            className={
                              passwordErrors.specialCharError
                                ? `text-red-500`
                                : "text-green-500"
                            }
                          >
                            contain at least one special character.
                          </p>
                        </div>
                      )}
                      <div className="flex justify-end py-4">
                        <button
                          type="submit"
                          disabled={disabledButton || !isPasswordValid}
                          onClick={() => changePassword()}
                          className="bg-[#F9A257] hover:bg-white hover:text-[#F9A257] border border-[#F9A257] text-white font-bold px-5 py-2.5 text-sm w-full sm:w-auto rounded focus:outline-none focus:shadow-outline"
                        >
                          {" "}
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>{" "}
            {modalIsOpen && (
              <ModaleDelete
                modalIsOpen={modalIsOpen}
                closeModal={() => setIsOpen(false)}
                deleteUser={() => deleteUser()}
                disabledButton={disabledButton}
              />
            )}
            {/*  
                    {openOtp && <ModalOtp
                        modalIsOpen={openOtp}
                        closeModal={() => setOpenOtp(false)}
                        disconnettiOtp={() => disconnettiOtp()}
                        disabledButton={disabledButton}
                        otp={otp}
                        setOtp={setOtp}
                        handleInput={(e) => setOtp(e.target.value)}
                    />} */}
          </div>
        )}
      </div>
    </div>
  );
}
