import { useEffect, useState } from "react";
import { instagramCheck } from "../utils/atom/instagram";
import { useRecoilState } from "recoil";
import LogoEsteso from "../assets/YourVisualData/logo scritta nera.png";
import { useNavigate } from "react-router-dom";

const GoogleAnalyticsProjects = ({ cookies, larghezzaPagina }) => {
  const [, setInstagram] = useRecoilState(instagramCheck);

  async function checkTag() {
    if (await getTag()) {
    }
  }
  useEffect(() => {
    checkTag();
  }, []);

  const navigate = useNavigate();

  async function getTag() {
    var url = window.location.search;
    var urlParams = new URLSearchParams(url);
    const code = urlParams.get("confirmation");

    if (code) {
      const url = process.env.REACT_APP_URL_BACKEND + "auth/email-confirmation?confirmation=" + code;
      const result = await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.error("Error fetching Google Analytics projects:", error);
          return true;
        });
      if (result && !result.error) {
        setTimeout(() => {
          navigate("/login?emailConfirmed=true");
        }, 2000);
        setInstagram(true);
        checkError("Email confirmed successfully");
        return true;
      } else {
        setInstagram(null);
        checkError(result.error?.message, true);
        return false;
      }
    } else {
      setInstagram(null);
      setMessages("Non esiste il codice di accesso");
      setInstagram(null);
      return false;
    }
  }
  const [error, setError] = useState(undefined);
  const [messages, setMessages] = useState(undefined);
  // function check error and get the message
  const checkError = (message, bool) => {
    setMessages(message);
    setError(bool);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[#FFD78A]  to-[#F4762D]">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full flex flex-col items-center">
        <img src={LogoEsteso} alt="Logo" className="h-10 mb-6" />
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">
          LOGIN
        </h1>
        {messages && (
          <p className="bg-green-100 text-green-700 p-4 rounded-lg mb-4 w-full text-center">
            {messages}
          </p>
        )}
        {error && (
          <p className="bg-red-100 text-red-700 p-4 rounded-lg mb-4 w-full text-center">
            <a
              className="underline text-blue-600 hover:text-blue-800"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => window.close()}
              href="mailto:info@yourvisualdata.com"
            >
              Contatta l'assistenza
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default GoogleAnalyticsProjects;
