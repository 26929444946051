// utils/gtm.js
const pushEventsToDataLayer = (event) => {
  if (window.dataLayer) {
    window.dataLayer.push(event);
  } else {
    console.error("dataLayer is not defined");
  }
};

export default pushEventsToDataLayer;
