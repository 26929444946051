import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AnalyticsChart from "../components/lineChart";
import AnalyticsChartMin from "../components/lineChartMin";
import DoughnutChart from "../components/doughnutChart";
import { addDays, format, set } from "date-fns";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Loader from "../components/loader";
import Modal from "../components/modalError";
import ComparedatePicker from "../components/ComparedatePicker";
import {
  projectsState,
  LogoutCache,
  RemoveAllProjects,
} from "../utils/atom/projects";
import { useRecoilState } from "recoil";
import queryString from "query-string";
import pushEventsToDataLayer from "../utils/gtm";
import determineIntervalType from "../utils/periodata";
import UpdateGoogle from "../components/updateGoogle";

function containsOnlyNumbers(str) {
  const regex = /^\d+$/;
  return regex.test(str);
}

const initialData = [
  { id: "1", type: "main", title: "Users", position: 0, value: undefined },
  { id: "9", type: "line", title: "Users", position: 0, value: undefined },
  { id: "2", type: "line", title: "New Users", position: 1, value: undefined },
  { id: "3", type: "line", title: "Sessions", position: 2, value: undefined },
  {
    id: "4",
    type: "line",
    title: "Number of Sessions per User",
    position: 3,
    value: undefined,
    media: true,
  },
  { id: "5", type: "line", title: "Page Views", position: 4, value: undefined },
  {
    id: "6",
    type: "line",
    title: "Pages/Session",
    position: 5,
    value: undefined,
    media: true,
  },
  {
    id: "7",
    type: "line",
    title: "Avg. Session Duration",
    position: 6,
    value: undefined,
    media: true,
  },
  {
    id: "8",
    type: "line",
    title: "Bounce Rate",
    position: 7,
    value: undefined,
    media: true,
  },
  {
    id: "10",
    type: "doughnut",
    title: "date_range_0",
    position: 0,
    value: undefined,
  },
];

const initialArray = {
  Demographics: [
    { item: [], name: "Language", mobile: false, nameReal: "languageCode" },
    { item: [], name: "Country", mobile: false, nameReal: "country" },
    { item: [], name: "City", mobile: false, nameReal: "city" },
  ],
  System: [
    { item: [], name: "Browser", mobile: false, nameReal: "browser" },
    {
      item: [],
      name: "Operating System",
      mobile: false,
      nameReal: "operatingSystem",
    },
  ],
  Mobile: [
    {
      item: [],
      name: "Operating System",
      mobile: true,
      nameReal: "operatingSystem",
    },
    {
      item: [],
      name: "Screen Resolution",
      mobile: true,
      nameReal: "screenResolution",
    },
  ],
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const GoogleAnalyticsProjects = ({ cookies, color, setColor }) => {
  const router = useParams();
  useEffect(() => {
    document.title = "Overview | YourVisualData";
  }, []);

  const [updateGoogle, setUpdateGoggle] = useState([]);
  const [isOpenC, setIsOpenC] = useState(false);

  useEffect(() => {
    setIsOpenC(cookies.get("updateGoogle"));
  }, []);

  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const [dateStartCompare, setDateStartCompare] = useState(null);
  const [dateEndCompare, setDateEndCompare] = useState(null);

  const [isComparing, setIsComparing] = useState(false);

  const allMetric = [
    { name: "Users", id: 0, value: "totalUsers" },
    { name: "New Users", id: 1, value: "newUsers" },
    { name: "Sessions", id: 2, value: "sessions" },
    { name: "Number of Sessions per User", id: 3, value: "sessionsPerUser" },
    { name: "Page Views", id: 4, value: "screenPageViews" },
    { name: "Pages/Session", id: 5, value: "screenPageViewsPerSession" },
    { name: "Avg. Session Duration", id: 6, value: "averageSessionDuration" },
    { name: "Bounce Rate", id: 7, value: "bounceRate" },
  ];
  const secondMetric = [
    { name: "Select a metric", id: -1, value: undefined },
    { name: "Users", id: 0, value: "totalUsers" },
    { name: "New Users", id: 1, value: "newUsers" },
    { name: "Sessions", id: 2, value: "sessions" },
    { name: "Number of Sessions per User", id: 3, value: "sessionsPerUser" },
    { name: "Page Views", id: 4, value: "screenPageViews" },
    { name: "Pages/Session", id: 5, value: "screenPageViewsPerSession" },
    { name: "Avg. Session Duration", id: 6, value: "averageSessionDuration" },
    { name: "Bounce Rate", id: 7, value: "bounceRate" },
  ];

  const [dataLine, setDataLine] = useState(0);
  const [dataLineSecond, setDataLineSecond] = useState(-1);

  const [loader, setLoader] = useState(true);
  const [loaderTablle, setLoaderTable] = useState(true);

  const fetchAnalyticsProjects = async (
    start,
    end,
    startCompare,
    endCompare,
    compare
  ) => {
    const id = router.project;
    const profile = router.profile;
    if (!containsOnlyNumbers(id) && !containsOnlyNumbers(profile)) {
      navigate("/");
      return;
    }
    setLoader(true);
    const token = cookies.get("jwt");
    if (!token) {
      navigate("/login");
      return;
    }
    const startDate = start;
    const endDate = end;

    // check dateStart and dateEnd is date format
    if (!startDate || !endDate) {
      setLoader(false);
      return;
    }
    const dateRanges = [
      {
        startDate: startDate,
        endDate: endDate,
      },
    ];

    if (compare) {
      setIsComparing(true);
      const startDateCompare = startCompare;
      const endDateCompare = endCompare;
      if (!startDateCompare || !endDateCompare) {
        setLoader(false);
        return;
      }

      dateRanges.push({
        startDate: startDateCompare,
        endDate: endDateCompare,
      });
      setDateStartCompare(startDateCompare);
      setDateEndCompare(endDateCompare);
    } else {
      setIsComparing(false);
    }

    const data = {
      query: {
        metrics: [
          { name: "totalUsers" },
          { name: "newUsers" },
          { name: "sessions" },
          { name: "sessionsPerUser" },
          { name: "screenPageViews" },
          { name: "screenPageViewsPerSession" },
          { name: "averageSessionDuration" },
          { name: "bounceRate" },
        ],
        dateRanges: dateRanges,
        dimensions: [
          {
            name: "date",
          },
          {
            name: "hour",
          },
          {
            name: "month",
          },
          {
            name: "week",
          },
        ],
        orderBys: [
          {
            dimension: {
              dimensionName: "date",
              orderType: "ALPHANUMERIC",
            },
          },
        ],
      },
      profile: profile,
    };

    const url = process.env.REACT_APP_URL_BACKEND + "progettos/" + id;
    await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data.error.message === "no token") {
            console.error("no token");
            RemoveAllProjects(setProjects);
            return navigate("/");
          }
          setModalError(true);
          return;
        }
        setDateStart(startDate);
        setDateEnd(endDate);
        setData(data.project.rows || []);
      })
      .catch((error) => {
        console.error(error);
        setModalError(true);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  useEffect(() => {
    const propID = router.project;
    const properties = router.properties;
    if (!containsOnlyNumbers(propID) || !containsOnlyNumbers(properties)) {
      console.error("Invalid project ID");
      return navigate("/");
    }

    const urlParams = new URLSearchParams(location.search);

    // Ottieni le date dai parametri di query, se disponibili
    const paramDataStart = convertDateFormat(urlParams.get("dateStart"));
    const paramDataEnd = convertDateFormat(urlParams.get("dateEnd"));

    const paramDataStartCompare = convertDateFormat(
      urlParams.get("dateStartCompare")
    );
    const paramDataEndCompare = convertDateFormat(
      urlParams.get("dateEndCompare")
    );
    // Imposta le date di default se i parametri non sono presenti
    const today = new Date();
    const lastWeek = addDays(today, -30);
    const yesterday = addDays(today, -1);

    // Usa le date dai parametri se disponibili, altrimenti usa le date di default
    const start = paramDataStart || format(lastWeek, "yyyy-MM-dd");
    const end = paramDataEnd || format(yesterday, "yyyy-MM-dd");

    setTypeSelected(determineIntervalType(start, end));
    // if (paramDataStartCompare && paramDataEndCompare) {
    //   setTypeSelectedCompare(
    //     determineIntervalType(paramDataStartCompare, paramDataEndCompare)
    //   );
    // }
    setDateStart(start);
    setDateEnd(end);
    setDateStartCompare(paramDataStartCompare);
    setDateEndCompare(paramDataEndCompare);
    setIsComparing(paramDataStartCompare && paramDataEndCompare);
    fetchAnalyticsProjects(
      start,
      end,
      paramDataStartCompare,
      paramDataEndCompare,
      paramDataStartCompare && paramDataEndCompare
    );
    fetchTable(
      start,
      end,
      paramDataStartCompare,
      paramDataEndCompare,
      paramDataStartCompare && paramDataEndCompare,
      "languageCode",
      false,
      "Demographics"
    );
  }, [router]);

  const convertDateFormat = (dateString) => {
    if (!containsOnlyNumbers(dateString)) {
      return null;
    }
    if (dateString?.length !== 8) {
      return null;
    }

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // Mese basato su 0
    const day = dateString.substring(6, 8);

    // Crea una nuova data in formato UTC
    const date = new Date(Date.UTC(year, month, day));

    // Converti la data in formato yyyy-MM-dd
    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  const [pos, setPos] = useState([]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(pos, result.source.index, result.destination.index);
    await cookies.set("positions", JSON.stringify(items));
    setPos(items);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const positions = cookies.get("positions");
    const initialData_ = positions || initialData;
    setPos(initialData_);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const itemButton = [
    {
      id: 1,
      name: "Hourly",
      value: "hourly",
    },
    {
      id: 2,
      name: "Daily",
      value: "daily",
    },
    {
      id: 3,
      name: "Monthly",
      value: "monthly",
    },
    {
      id: 4,
      name: "Weekly",
      value: "weekly",
    },
  ];

  const [buttonSelected, setButtonSelected] = useState("daily");

  const [typeSelected, setTypeSelected] = useState("last30Days");
  const [typeSelectedCompare, setTypeSelectedCompare] =
    useState("previousPeriod");
  const [labelMain, setLabelMain] = useState("Users");
  const [labelSecond, setLabelSecond] = useState("New Users");

  const [, setProjects] = useRecoilState(projectsState);

  const [modalError, setModalError] = useState(false);

  const logout = async () => {
    try {
      const token = cookies.get("jwt");
      const user_ = cookies.get("user");
      if (user_ || user_.id) {
        const response = await fetch(
          process.env.REACT_APP_URL_BACKEND + "users/" + user_.id,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {},
          }
        );
        await response.json();
      }
      cookies.remove("user");
      cookies.remove("jwt");
      cookies.remove("color");
      cookies.remove("positions");
      cookies.remove("Wizard");
      cookies.remove("Wizard_welcome");
      LogoutCache(setProjects, cookies);
      navigate("/login");
    } catch (error) {
      cookies.remove("user");
      cookies.remove("jwt");
      cookies.remove("color");
      cookies.remove("positions");
      cookies.remove("Wizard");
      cookies.remove("Wizard_welcome");
      LogoutCache(setProjects, cookies);
      navigate("/login");
    }
  };

  useEffect(() => {
    if (isComparing) {
      const positions = cookies.get("positions");
      const init = positions || initialData;
      const initialData_ = init.filter((item) => item.title === "date_range_1");
      if (initialData_.length === 0)
        initialData.push({
          id: "11",
          type: "doughnut",
          title: "date_range_1",
          position: 0,
          value: undefined,
        });

      setPos(initialData);
    } else {
      const positions = cookies.get("positions");
      const init = positions || initialData;
      const initialData_ = init.filter((item) => item.title !== "date_range_1");
      setPos(initialData_);
    }
  }, [isComparing]);

  const [dataTabella, setDataTabella] = useState(
    JSON.parse(JSON.stringify(initialArray))
  );

  const [selectedCategory, setSelectedCategory] = useState(
    initialArray["Demographics"]?.find((a) => a.nameReal === "languageCode")
      ?.name +
      "+" +
      "Demographics"
  );
  const [selectedData, setSelectedData] = useState(dataTabella.language || []);

  const handleCategoryClick = (category, mobile, categoryMain) => {
    const cat = dataTabella[categoryMain].find((a) => a.nameReal === category);
    setSelectedCategory(cat.name + "+" + categoryMain);
    if (
      dataTabella[categoryMain].find((a) => a.nameReal === category).item
        .length === 0
    ) {
      fetchTable(
        dateStart,
        dateEnd,
        dateStartCompare,
        dateEndCompare,
        isComparing,
        category,
        mobile,
        categoryMain
      );
    } else {
      setSelectedData(cat.item);
    }
  };

  const fetchTable = async (
    start,
    end,
    startCompare,
    endCompare,
    compare,
    dimension,
    mobile,
    categoryMain,
    reset
  ) => {
    const id = router.project;
    const profile = router.profile;
    if (!containsOnlyNumbers(id) && !containsOnlyNumbers(profile)) {
      navigate("/");
      return;
    }
    setLoaderTable(true);
    const token = cookies.get("jwt");
    if (!token) {
      navigate("/login");
      return;
    }
    const startDate = start;
    const endDate = end;

    // check dateStart and dateEnd is date format
    if (!startDate || !endDate) {
      setLoaderTable(false);
      return;
    }
    const dateRanges = [
      {
        startDate: startDate,
        endDate: endDate,
      },
    ];

    if (compare) {
      setIsComparing(true);
      const startDateCompare = startCompare;
      const endDateCompare = endCompare;
      if (isComparing && (!startDateCompare || !endDateCompare)) {
        setLoaderTable(false);
        return;
      }

      dateRanges.push({
        startDate: startDateCompare,
        endDate: endDateCompare,
      });
    }

    const data = {
      query: {
        metrics: [
          { name: "totalUsers" },
          { name: "newUsers" },
          { name: "sessions" },
          { name: "sessionsPerUser" },
          { name: "screenPageViews" },
          { name: "screenPageViewsPerSession" },
          { name: "averageSessionDuration" },
          { name: "bounceRate" },
        ],
        dateRanges: dateRanges,
        dimensions: [
          {
            name: dimension,
          },
        ],
        orderBys: [
          {
            dimension: {
              dimensionName: dimension,
              orderType: "ALPHANUMERIC",
            },
          },
        ],
      },
      profile: profile,
    };

    if (mobile) {
      data.query.dimensionFilter = {
        filter: {
          fieldName: "deviceCategory",
          stringFilter: {
            value: "Mobile",
          },
        },
      };
    }

    const url = process.env.REACT_APP_URL_BACKEND + "progettos/" + id;
    await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data.error.message === "no token") {
            console.error("no token");
            RemoveAllProjects(setProjects);
            return navigate("/");
          }
          setModalError(true);
          return;
        }
        const rows = data?.project?.rows || [];
        setUpdateGoggle(data.annotations || []);
        if (rows.length > 0) {
          const dataTabella_ = rows.map((row) => {
            return {
              name: row.dimensionValues[0].value,
              compare:
                row.dimensionValues.length > 1
                  ? row.dimensionValues[1].value
                  : "date_range_0",
              date:
                row.dimensionValues.length > 1 &&
                row.dimensionValues[1].value === "date_range_1"
                  ? dateRanges[1].startDate + " / " + dateRanges[1].endDate
                  : dateRanges[0].startDate + " / " + dateRanges[0].endDate,
              totalUsers: row.metricValues[0].value,
              newUsers: row.metricValues[1].value,
              sessions: row.metricValues[2].value,
              sessionsPerUser: row.metricValues[3].value,
              screenPageViews: row.metricValues[4].value,
              screenPageViewsPerSession: row.metricValues[5].value,
              averageSessionDuration: row.metricValues[6].value,
              bounceRate: row.metricValues[7].value,
            };
          });
          // sort value desc

          let updatedDataTabella = { ...dataTabella };
          if (reset) {
            updatedDataTabella = {
              ...JSON.parse(JSON.stringify(initialArray)),
            };
            setDataTabella(initialArray);
          }

          const groupedData = dataTabella_.reduce((acc, item) => {
            if (!acc[item.name]) {
              acc[item.name] = [];
            }
            acc[item.name].push(item);
            return acc;
          }, {});

          const sortedDataArray = Object.entries(groupedData)
            .map(([name, items]) => ({
              name,
              items: items.sort((a, b) => a.compare.localeCompare(b.compare)),
            }))
            .sort((a, b) => b.items[0].totalUsers - a.items[0].totalUsers)
            .slice(0, 10);

          updatedDataTabella[categoryMain].forEach((item) => {
            if (item.nameReal === dimension) {
              item.item = sortedDataArray;
            }
          });

          setDataTabella(updatedDataTabella);
          setSelectedData(sortedDataArray);
          // const cat = updatedDataTabella[categoryMain].find((a) => a.nameReal === dimension)
          // setSelectedCategory(cat.name);
        }
        if (data.error && data.error.message === "no refresh token") {
          navigate("/");
        }
      })
      .catch((error) => {
        setModalError(true);
      })
      .finally(() => {
        setLoaderTable(false);
      });
  };

  function formatDuration(seconds) {
    if (!seconds || isNaN(seconds)) return "00:00:00";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    // Utilizziamo date-fns per formattare il tempo
    const formatted = format(
      new Date(0, 0, 0, hours, minutes, secs),
      "HH:mm:ss"
    );

    return formatted;
  }

  function formatIfDecimal(num) {
    let num_;
    // Controlla se il numero ha una parte decimale
    if (num % 1 !== 0) {
      num = parseFloat(num);
      num_ = num.toFixed(2).toLocaleString("en-US");
    } else {
      num = parseFloat(num);
      num_ = num.toLocaleString("en-US");
    }
    // // check not is Nan
    // if (isNaN(num_) || num_ === "Infinity" || num_ === "-Infinity") {
    //   return "-"
    // }
    return num_;
  }

  return (
    <div className="" id="intro">
      <div className="p-20 ">
        {loader ? (
          <div className="h-[80vh] bg-white flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="flex justify-end items-center ">
              <div className="w-fit">
                <ComparedatePicker
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  setTypeSelected={setTypeSelected}
                  isComparing={isComparing}
                  dateStartCompare={dateStartCompare}
                  dateEndCompare={dateEndCompare}
                  typeSelected={typeSelected}
                  setTypeSelectedCompare={setTypeSelectedCompare}
                  typeSelectedCompare={typeSelectedCompare}
                />
              </div>
            </div>
            {data?.length === 0 ? (
              <h1 className="text-center text-2xl my-20">
                No data for this period
              </h1>
            ) : (
              <>
                {data?.length > 0 && (
                  <div className="flex justify-between items-center my-4">
                    <div className="flex gap-4">
                      <select
                        className="border border-gray-300 rounded-md p-2 max-w-[160px]"
                        value={dataLine}
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "first_dimension_click",
                          });
                        }}
                        onChange={(e) => {
                          setDataLine(e.target.value);
                          setLabelMain(
                            allMetric.find(
                              (metric) =>
                                metric.id === parseFloat(e.target.value)
                            ).name
                          );
                          if (dataLineSecond === parseFloat(e.target.value)) {
                            setDataLineSecond(
                              secondMetric.find(
                                (metric) =>
                                  metric.id !== parseFloat(e.target.value)
                              ).id
                            );
                          }
                        }}
                      >
                        {allMetric
                          .filter(
                            (metric) => metric.id !== parseFloat(dataLineSecond)
                          )
                          .map((metric, index) => {
                            return (
                              <option key={index} value={metric.id}>
                                {metric.name}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        className="border border-gray-300 rounded-md p-2 max-w-[160px]"
                        value={dataLineSecond}
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "second_dimension_click",
                          });
                        }}
                        onChange={(e) => {
                          setDataLineSecond(e.target.value);
                          setLabelSecond(
                            secondMetric.find(
                              (metric) =>
                                metric.id === parseFloat(e.target.value)
                            ).name
                          );
                        }}
                      >
                        {secondMetric
                          .filter(
                            (metric) => metric.id !== parseFloat(dataLine)
                          )
                          .map((metric, index) => {
                            return (
                              <option key={index} value={metric.id}>
                                {metric.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="flex">
                      {itemButton.map((item, index) => {
                        return (
                          <button
                            key={index}
                            className={`mx-2
                         rounded-md px-2 py-1 text-sm
                         text-gray-700 border border-gray-300
                      ${
                        buttonSelected === item.value
                          ? "bg-gray-300"
                          : "bg-white hover:bg-gray-100"
                      } 
                          `}
                            onClick={() => {
                              setButtonSelected(item.value);
                              pushEventsToDataLayer({
                                event: "button_click",
                                "data-click-text": item.value,
                              });
                            }}
                          >
                            {item.name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
                {data?.length > 0 && (
                  <div className="flex gap-[12px] flex-wrap  items-end my-4 mb-[36px]">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="draggable" direction="vertical">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            className="list-container flex gap-[12px] flex-wrap   "
                            ref={provided.innerRef}
                          >
                            {pos.map((item, index) => (
                              <Draggable
                                key={index + item.type}
                                draggableId={`draggable-img-${index}+${item.type}`}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="w-fit max-w-full "
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {item.type === "main" && (
                                      <div
                                        style={{ width: windowWidth - 10 * 16 }}
                                      >
                                        <AnalyticsChart
                                          rows={data}
                                          position={dataLine}
                                          buttonSelected={buttonSelected}
                                          color={color}
                                          secondPosition={dataLineSecond}
                                          labelMain={labelMain}
                                          labelSecond={labelSecond}
                                          dateStartCompare={dateStartCompare}
                                          dateEndCompare={dateEndCompare}
                                          isComparing={isComparing}
                                          dateStart={dateStart}
                                          dateEnd={dateEnd}
                                          annotations={
                                            isOpenC ? updateGoogle : []
                                          }
                                        />
                                      </div>
                                    )}
                                    {item.type === "line" && (
                                      <div
                                        className="border-r px-4  w-[240px]  cursor-pointer min-h-[70px] flex flex-col items-start justify-center gap-1"
                                        onClick={() => {
                                          setLabelMain(item.title);
                                          setDataLine(item.position);
                                        }}
                                      >
                                        <h4 className="text-sm">
                                          {item.title}
                                        </h4>
                                        {item.value2 ? (
                                          item.title === "Bounce Rate" ? (
                                            <>
                                              <h4
                                                className={
                                                  item.value - item.value2 > 0
                                                    ? `text-green-500`
                                                    : `text-red-500`
                                                }
                                              >
                                                {(
                                                  item.value - item.value2
                                                )?.toFixed(2)}{" "}
                                                %
                                              </h4>
                                              <h4 className="text-sm">
                                                {item.value} vs {item.value2}
                                              </h4>
                                            </>
                                          ) : (
                                            <>
                                              <h4
                                                className={
                                                  ((item.value - item.value2) /
                                                    item.value2) *
                                                    100 >
                                                  0
                                                    ? `text-green-500`
                                                    : `text-red-500`
                                                }
                                              >
                                                {(
                                                  ((item.value - item.value2) /
                                                    item.value2) *
                                                  100
                                                )?.toFixed(2)}{" "}
                                                %
                                              </h4>
                                              <h4 className="text-sm">
                                                {" "}
                                                {item.title ===
                                                "Avg. Session Duration"
                                                  ? formatDuration(item.value)
                                                  : formatIfDecimal(item.value)}
                                                {item.title === "Bounce Rate" &&
                                                  "%"}{" "}
                                                vs{" "}
                                                {item.title ===
                                                "Avg. Session Duration"
                                                  ? formatDuration(item.value2)
                                                  : formatIfDecimal(
                                                      item.value2
                                                    )}
                                                {item.title === "Bounce Rate" &&
                                                  "%"}
                                              </h4>
                                            </>
                                          )
                                        ) : (
                                          <h4 className="text-sm">
                                            {item.title ===
                                            "Avg. Session Duration"
                                              ? formatDuration(item.value)
                                              : formatIfDecimal(item.value)}
                                            {item.title === "Bounce Rate" &&
                                              "%"}
                                          </h4>
                                        )}
                                        <div className="w-[174px] h-[35px]">
                                          {data && (
                                            <AnalyticsChartMin
                                              rows={data}
                                              position={item.position}
                                              buttonSelected={buttonSelected}
                                              setPos={setPos}
                                              pos={pos}
                                              color={color}
                                              setLabelMain={setLabelMain}
                                              dateStartCompare={
                                                dateStartCompare
                                              }
                                              dateEndCompare={dateEndCompare}
                                              isComparing={isComparing}
                                              dateStart={dateStart}
                                              dateEnd={dateEnd}
                                              media={item.media}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {item.type === "doughnut" && (
                                      <div className="w-[400px] block flex justify-end">
                                        <div className=" h-[223px]">
                                          <DoughnutChart
                                            rows={
                                              isComparing
                                                ? data.filter(
                                                    (row) =>
                                                      row.dimensionValues[4]
                                                        ?.value === item.title
                                                  )
                                                : data
                                            }
                                            color={color}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </>
            )}
            {updateGoogle.length > 0 && (
              <UpdateGoogle
                annotation={updateGoogle}
                isOpenC={isOpenC}
                setIsOpenC={setIsOpenC}
                cookies={cookies}
              />
            )}
          </>
        )}
        {loaderTablle ? (
          <div className="h-[40vh] bg-white flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="flex gap-8">
            <div className="w-[20%]  ">
              <div>
                {Object.keys(dataTabella).map((category) => (
                  <ul key={category} className="">
                    <li className=" border-gray-500 border-b-2 pb-1 pl-2  mt-2">
                      <strong>{category}</strong>
                    </li>
                    {dataTabella[category].map((item, index) => {
                      return (
                        <li
                          className={
                            item.name + "+" + category === selectedCategory
                              ? "bg-[#E0EFF6] text-black text-sm p-2 pl-2 flex justify-between items-center"
                              : "cursor-pointer hover:underline text-[#0F91C9] text-sm p-2 pl-2"
                          }
                          key={index}
                          onClick={() => {
                            pushEventsToDataLayer({
                              event: "element_click",
                              "data-click-text": (
                                item.name?.toLowerCase() +
                                "-" +
                                category?.toLowerCase()
                              )?.replace(/\s/g, "-"),
                            });
                            handleCategoryClick(
                              item.nameReal,
                              item.mobile,
                              category
                            );
                          }}
                        >
                          <span>{item.name}</span>
                          {item.name + "+" + category === selectedCategory && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#233a3a"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path
                                d="M12 2l.324 .005a10 10 0 1 1 -.648 0l.324 -.005zm.613 5.21a1 1 0 0 0 -1.32 1.497l2.291 2.293h-5.584l-.117 .007a1 1 0 0 0 .117 1.993h5.584l-2.291 2.293l-.083 .094a1 1 0 0 0 1.497 1.32l4 -4l.073 -.082l.064 -.089l.062 -.113l.044 -.11l.03 -.112l.017 -.126l.003 -.075l-.007 -.118l-.029 -.148l-.035 -.105l-.054 -.113l-.071 -.111a1.008 1.008 0 0 0 -.097 -.112l-4 -4z"
                                strokeWidth="0"
                                fill="currentColor"
                              />
                            </svg>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                ))}
              </div>
            </div>
            <div className="w-full">
              <table className="min-w-full  ">
                <thead className="">
                  <tr>
                    <th className=" border-b-2 border-gray-500 text-left pl-6 pb-1">
                      {selectedCategory.split("+")[0]}
                    </th>
                    <th className="border-b-2 border-gray-500 w-[15%] text-right pr-2">
                      {allMetric[0].name}
                    </th>
                    <th className=" border-b-2 border-gray-500 w-[20%] text-left pl-2">
                      % {allMetric[0].name}
                    </th>
                  </tr>
                </thead>
                {selectedData.length > 0 ? (
                  selectedData.map((group, groupIndex) => (
                    <tbody key={groupIndex} className="text-sm ">
                      <tr
                        className={
                          group.items?.length === 1 && groupIndex % 2 === 0
                            ? "bg-gray-200 border-b border-gray-400 tooltipo"
                            : group.items?.length > 1
                            ? " border-b border-gray-400 bg-gray-200 tooltipo"
                            : "border-b border-gray-400 tooltipo"
                        }
                      >
                        <th
                          className=" p-2 text-left text-[#0F91C9] text-normal border-b border-gray-400 "
                          onClick={() => {
                            pushEventsToDataLayer({
                              event: "element_click",
                              "data-click-text": group.name
                                ?.toLowerCase()
                                ?.replace(/[\s]/g, "-"),
                            });
                          }}
                        >
                          <span className="text-black pr-1">
                            {groupIndex + 1}.
                          </span>
                          {group.name}
                          <a
                            onClick={() => {
                              pushEventsToDataLayer({
                                event: "request_information",
                                "data-click-text": group.name
                                  ?.toLowerCase()
                                  ?.replace(/[\s]/g, "-"),
                              });
                            }}
                            className="tooltiptexto hover:underline"
                            href="/form"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click functionality coming soon
                          </a>
                        </th>
                        {group.items?.length === 1 ? (
                          <th className=" p-2 text-right font-normal">
                            {formatIfDecimal(
                              group.items[0][allMetric[0].value]
                            )}
                          </th>
                        ) : (
                          <th className=" p-2 text-right "></th>
                        )}
                        {group.items?.length === 1 ? (
                          <th className=" p-2 text-left  flex gap-2 items-center">
                            <div
                              className={` bg-[#058dc7] h-4`}
                              style={{
                                width: isNaN(
                                  (group.items[0][allMetric[0].value] /
                                    pos.find(
                                      (p) =>
                                        p.type === "line" &&
                                        p.title === allMetric[0].name
                                    ).value) *
                                    100
                                )
                                  ? 0
                                  : (group.items[0][allMetric[0].value] /
                                      pos.find(
                                        (p) =>
                                          p.type === "line" &&
                                          p.title === allMetric[0].name
                                      ).value) *
                                    100,
                                //  <div className={`w-[${String((group.items[0][allMetric[0].value] / pos.find((p) => p.type === "line" && p.title === allMetric[0].name).value) * 100).replace(/\./g, ',')}%] bg-black h-4`}>
                              }}
                            ></div>
                            <div className="block font-normal ">
                              {(
                                (group.items[0][allMetric[0].value] /
                                  pos.find(
                                    (p) =>
                                      p.type === "line" &&
                                      p.title === allMetric[0].name
                                  ).value) *
                                100
                              )?.toFixed(2)}{" "}
                              %
                            </div>
                          </th>
                        ) : (
                          <th className=" p-2 text-right "></th>
                        )}
                      </tr>
                      {group.items.length > 1 &&
                        group.items.map((item, index) => {
                          return (
                            <tr key={index} className=" ">
                              <td className="border-b border-gray-200 p-2">
                                {item.date}
                              </td>
                              <td className="border-b border-gray-200 p-2 text-right font-normal">
                                {formatIfDecimal(item[allMetric[0].value])}
                              </td>
                              <td className="border-b border-gray-200 p-2 text-left flex gap-2 items-center">
                                <div
                                  className={`w-[56.3%] bg-[#058dc7] h-4`}
                                  style={{
                                    width: isNaN(
                                      (item[allMetric[0].value] /
                                        pos.find(
                                          (p) =>
                                            p.type === "line" &&
                                            p.title === allMetric[0].name
                                        ).value) *
                                        100
                                    )
                                      ? 0
                                      : (item[allMetric[0].value] /
                                          pos.find(
                                            (p) =>
                                              p.type === "line" &&
                                              p.title === allMetric[0].name
                                          ).value) *
                                        100,
                                    //  <div className={`w-[${String((group.items[0][allMetric[0].value] / pos.find((p) => p.type === "line" && p.title === allMetric[0].name).value) * 100).replace(/\./g, ',')}%] bg-black h-4`}>
                                  }}
                                ></div>
                                <div className="block font-normal">
                                  {(
                                    (item[allMetric[0].value] /
                                      pos.find(
                                        (p) =>
                                          p.type === "line" &&
                                          p.title === allMetric[0].name
                                      ).value) *
                                    100
                                  )?.toFixed(2)}{" "}
                                  %
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      {group.items.length > 1 ? (
                        <tr className=" ">
                          <td className=" p-2">
                            <strong>% Change</strong>
                          </td>
                          <td
                            className={
                              (
                                ((group.items[0][allMetric[0].value] -
                                  group.items[1][allMetric[0].value]) /
                                  group.items[1][allMetric[0].value]) *
                                100
                              )?.toFixed(2) > 0
                                ? `p-2 text-right text-green-500`
                                : ` p-2 text-right text-red-500`
                            }
                          >
                            {(
                              ((group.items[0][allMetric[0].value] -
                                group.items[1][allMetric[0].value]) /
                                group.items[1][allMetric[0].value]) *
                              100
                            )?.toFixed(2)}{" "}
                            %
                          </td>
                          <td
                            className={
                              (group.items[0][allMetric[0].value] /
                                pos.find(
                                  (p) =>
                                    p.type === "line" &&
                                    p.title === allMetric[0].name
                                ).value) *
                                100 -
                                (group.items[1][allMetric[0].value] /
                                  pos.find(
                                    (p) =>
                                      p.type === "line" &&
                                      p.title === allMetric[0].name
                                  ).value) *
                                  100 >
                              0
                                ? `p-2 text-left text-green-500`
                                : ` p-2 text-left text-red-500`
                            }
                          >
                            {(
                              (group.items[0][allMetric[0].value] /
                                pos.find(
                                  (p) =>
                                    p.type === "line" &&
                                    p.title === allMetric[0].name
                                ).value) *
                                100 -
                              (group.items[1][allMetric[0].value] /
                                pos.find(
                                  (p) =>
                                    p.type === "line" &&
                                    p.title === allMetric[0].name
                                ).value) *
                                100
                            )?.toFixed(2)}{" "}
                            %
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  ))
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="3" className="text-center">
                        There is no data for this view.
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        )}
      </div>
      {modalError && (
        <Modal
          modalIsOpen={modalError}
          closeModal={() => setModalError(false)}
          message="Non è stato possibile recuperare i dati"
          logout={true}
          logoutFunction={logout}
        />
      )}
    </div>
  );
};

export default GoogleAnalyticsProjects;
