import { useEffect } from "react";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import LogoEsteso from "../assets/YourVisualData/logo scritta nera.png";
import favicon from "../assets/YourVisualData/favicon.png";
import {
  GetProjects,
  projectsState,
  LogoutCache,
} from "../utils/atom/projects";
import { useRecoilState } from "recoil";
import Loader from "./loader";
import { createParamsSearch } from "../utils/params";
import queryString from "query-string";

import Select from "react-select";

import imgprofilo from "../assets/imgprofilo.png";
import pushEventsToDataLayer from "../utils/gtm";
import { set } from "date-fns";

export default function Header({
  cookies,
  reference,
  getReferenceProps,
  setProfile,
  color,
  setColor,
}) {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [projects, setProjects] = useRecoilState(projectsState);

  const navigate = useNavigate();
  const router = useParams();
  const [loader, setLoader] = useState(true);

  const [, setToken] = useState(false);


  useEffect(() => {
    setLoader(true);
    const user_ = cookies.get("user");
    const token = cookies.get("jwt");
    if (!token || !user_) {
      cookies.remove("user");
      cookies.remove("jwt");
      cookies.remove("color");
      cookies.remove("positions");
      cookies.remove("Wizard");
      cookies.remove("Wizard_welcome");
      LogoutCache(setProjects, cookies);
      setToken(false);
      navigate("/login");
      return;
    } else {
      if (getTag(token)) {
        setToken(true);
      }
      setLoader(false);
    }
  }, [router]);

  async function getTag(token) {
    const p = await GetProjects(false, projects, setProjects, token, cookies);
    cookies.remove("refresh")
    if (p === "Insufficient Permission") {
      return true;
    }
    if (p === "No capabilities") {
      return false;
    }
    if (p) {
      checkProject(p);
      return true;
    } else {
      cookies.remove("user");
      cookies.remove("jwt");
      cookies.remove("color");
      cookies.remove("positions");
      cookies.remove("Wizard");
      cookies.remove("Wizard_welcome");
      LogoutCache(setProjects, cookies);
      // setOpenModal(true);
      navigate("/login");
      return false;
    }
  }
  // get url
  const url = window.location.pathname;
  const channel = url.split("/")[1];

  function containsOnlyNumbers(str) {
    const regex = /^\d+$/;
    return regex.test(str);
  }

  const [allProfile, setAllProfile] = useState([]);

  const checkProject = (projParsed) => {
    const proj = Object.keys(projParsed);
    const allProfile = proj.map((profile) => {
      return {
        fullName: projParsed[profile].fullName,
        picture: projParsed[profile].picture,
        idGoogle: profile,
      };
    });
    setAllProfile(allProfile);
    if (router.properties && containsOnlyNumbers(router.profile)) {
      // create new object with all profile
      const profile = router.profile;
      const firstSelected = allProfile.find(
        (profile) => profile.idGoogle === router.profile
      );
      setSelectedOption({
        value: firstSelected,
        label: (
          <div className="flex items-center capitalize">
            <img
              src={firstSelected?.picture}
              alt="profile"
              className="w-6 h-6 rounded-full mr-2"
              onError={handleImageErrorSelected}
              referrerPolicy="no-referrer"
            />
            {firstSelected?.fullName}
          </div>
        ),
      });

      setProfileSelected(router.profile);
      setCurrentProfile(router.profile);
      const selectedProject = projParsed[router.profile]?.accounts.find(
        (account) => account.name === "accounts/" + router.properties
      );
      if (selectedProject) {
        setAccountSelected(selectedProject);
        setCurrentProject(selectedProject);
        if (router.project) {
          const selectedProperty = selectedProject.properties.find(
            (property) => property.name === "properties/" + router.project
          );
          if (selectedProperty) {
            setCurrentProperty(selectedProperty);
            setPropertySelected(selectedProperty);
          } else {
            setCurrentProperty(selectedProject.properties[0]);
            setPropertySelected(selectedProject.properties[0]);
            if (containsOnlyNumbers(channel)) {
              navigate(
                `/audiences-overview/${profile}/${
                  selectedProject.name.split("/")[1]
                }/${selectedProject.properties[0].name.split("/")[1]}`
              );
            } else {
              navigate(
                `/${channel}/${profile}/${selectedProject.name.split("/")[1]}/${
                  selectedProject.properties[0].name.split("/")[1]
                }?${createParamsSearch(queryParams)}`
              );
            }
            return;
          }
        } else {
          setCurrentProperty(selectedProject.properties[0]);
          setPropertySelected(selectedProject.properties[0]);
          if (containsOnlyNumbers(channel)) {
            navigate(
              `/audiences-overview/${profile}/${
                selectedProject.name.split("/")[1]
              }/${selectedProject.properties[0].name.split("/")[1]}`
            );
          } else {
            navigate(
              `/${channel}/${profile}/${selectedProject.name.split("/")[1]}/${
                selectedProject.properties[0].name.split("/")[1]
              }?${createParamsSearch(queryParams)}`
            );
          }
          return;
        }
      } else {
        const proj = Object.keys(projParsed);
        if (Object.keys(projParsed).length === 0) {
          return;
        }
        const profile = proj[0];
        const project = projParsed[profile]?.accounts;
        setCurrentProfile(proj[0]);
        setProfileSelected(proj[0]);
        setCurrentProject(project[0]);
        setAccountSelected(project[0]);
        setCurrentProperty(project[0].properties[0]);
        setPropertySelected(project[0].properties[0]);
        const prop = project[0].name.split("/")[1];
        const id = project[0].properties[0].name.split("/")[1];
        if (containsOnlyNumbers(channel)) {
          navigate(`/audiences-overview/${proj[0]}/${prop}/${id}`);
        } else {
          navigate(
            `/${channel}/${proj[0]}/${prop}/${id}?${createParamsSearch(
              queryParams
            )}`
          );
        }
        return;
      }
    } else {
      const proj = Object.keys(projParsed);
      if (Object.keys(projParsed).length === 0) {
        return;
      }
      const profile = proj[0];
      const project = projParsed[profile]?.accounts;
      setCurrentProfile(proj[0]);
      setProfileSelected(proj[0]);

      setCurrentProject(project[0]);
      setAccountSelected(project[0]);

      setCurrentProperty(project[0].properties[0]);
      setPropertySelected(project[0].properties[0]);

      const prop = project[0].name.split("/")[1];
      const id = project[0].properties[0].name.split("/")[1];
      navigate(`/audiences-overview/${proj[0]}/${prop}/${id}`);
      return;
    }
    return;
  };

  const [currentProfile, setCurrentProfile] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentProperty, setCurrentProperty] = useState(null);
  const handleChange = (id) => {
    const selectedProject = projects?.pageCache["page"]?.projects[
      currentProfile
    ].accounts?.find(
      (project) => project.name === "accounts/" + id.split("/")[0]
    );
    const selectedProperty = selectedProject.properties.find(
      (property) => property.name === "properties/" + id.split("/")[1]
    );
    if (selectedProject && selectedProperty) {
      navigate(`/audiences-overview/${currentProfile}/${id}`);
      return;
    }
    if (selectedProject && !selectedProperty) {
      const currentProject = selectedProject.name.split("/")[1];
      const currentProperty = selectedProject.properties[0].name.split("/")[1];
      if (containsOnlyNumbers(channel)) {
        navigate(
          `/audiences-overview/${currentProfile}/${currentProject}/${currentProperty}`
        );
      } else {
        navigate(
          `/${channel}/${currentProfile}/${currentProject}/${currentProperty}?${createParamsSearch(
            queryParams
          )}`
        );
      }
      return;
    }
    if (containsOnlyNumbers(channel)) {
      navigate(
        `/audiences-overview/${currentProfile}/${
          projects[0].name.split("/")[1]
        }/${projects[0].properties[0].name.split("/")[1]}`
      );
    } else {
      navigate(
        `/${channel}/${currentProfile}/${projects[0].name.split("/")[1]}/${
          projects[0].properties[0].name.split("/")[1]
        }?${createParamsSearch(queryParams)}`
      );
    }
  };
  useEffect(() => {
    try {
      const c = JSON.stringify(color);
      cookies.set("color", c);
    } catch {
      cookies.set("color", ["#058dc7", "#ed7e17"]);
    }
  }, [color]);

  const buttonColor = [
    {
      name: "Classic",
      color: ["#058dc7", "#ed7e17", "#c6e9f9", "#ed7e1733"],
    },
    {
      name: "Rock",
      color: ["#DB79CB", "#837BDB", "#7FDCD4", "#D1F0D8"],
    },
    {
      name: "Jazz",
      color: ["#096F26", "#982649", "#F4E5E2", "#EDF7DE"],
    },
  ];

  const [box, setBox] = useState(false);

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  const handleClickOutside = () => {
    setBox(false);
  };

  const [profileSelected, setProfileSelected] = useState(null);
  const [accountSelected, setAccountSelected] = useState(null);
  const [propertySelected, setPropertySelected] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleImageErrorSelected = (event) => {
    event.target.src = imgprofilo;
  };

  return (
    <>
      <header
        className=" top-0 bg-white z-50 border-b remove-border-no-bottom border-lightgray fixed w-full border-solid"
        style={{
          borderTopWidth: "0px",
          borderLeftWidth: "0px",
          borderRightWidth: "0px",
        }}
      >
        <div>
          <div className="flex items-center justify-between h-[70px] px-4 md:px-[10px] lg:px-[40px] gap-4">
            <div>
              <div
                className="block lg:hidden cursor-pointer"
                onClick={() => {
                  navigate(
                    "/audiences-overview/" +
                      currentProject.name.split("/")[1] +
                      "/" +
                      currentProperty.name.split("/")[1]
                  );
                }}
              >
                <LazyLoadImage src={favicon} width="40" />
              </div>
              <div
                className="hidden lg:flex cursor-pointer  items-end gap-1 w-fit"
                onClick={() => {
                  navigate(
                    "/audiences-overview/" +
                      currentProject.name.split("/")[1] +
                      "/" +
                      currentProperty.name.split("/")[1]
                  );
                }}
              >
                <LazyLoadImage src={LogoEsteso} width="150" />
                <span className="text-[10px] whitespace-nowrap h-[17px]">
                  | Powered by Fortop
                </span>
              </div>
            </div>
            {!loader ? (
              <div className="flex gap-2 md:gap-8 items-center justify-between  w-full">
                {projects.pageCache["page"]?.projects.length === 0 ? (
                  <div className="text-gray-500 ml-10">
                    You have no projects associated with this account
                  </div>
                ) : currentProperty ? (
                  <div className="flex gap-2 md:gap-4 items-center justify-between w-full ml-10">
                    <div
                      className="flex gap-2 md:gap-4 items-center justify-start p-2 "
                      id="project-property"
                    >
                      <div
                        className="hover:bg-gray-100 p-2 cursor-pointer rounded-md"
                        onClick={() => setBox(!box)}
                        // ref={reference2}
                        // {...getReferenceProps2()}
                      >
                        <div className="flex items-center">
                          <h4 className="text-[#8c8c8c] font-bold text-sm">
                            {currentProject.displayName}
                          </h4>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#8c8c8c"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 6l6 6l-6 6" />
                          </svg>
                        </div>
                        <div className="flex items-center">
                          <h3>{currentProperty.displayName}</h3>
                          <svg
                            className="rotate-90"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#000"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 6l6 6l-6 6" />
                          </svg>
                        </div>
                      </div>

                      {/* <div className="flex items-center gap-2 ">
                      <span className="text-gray-500 hidden xl:block">
                        Account:
                      </span>
                      <select
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "account_select",
                          });
                        }}
                        className="border border-gray-300 rounded-md p-2 max-w-[250px]"
                        onChange={handleChange}
                        value={currentProject.name.split("/")[1]}
                      >
                        {projects?.pageCache["page"]?.projects[
                          currentProfile
                        ].accounts.map((project) => (
                          <option
                            key={project.name}
                            value={`${project.name.split("/")[1]}`}
                          >
                            {project.displayName}
                          </option>
                        ))}
                      </select>
                    </div> */}
                      {/* <div className="flex items-center gap-2">
                      <span className="text-gray-500 hidden xl:block">
                        Property:
                      </span>
                      <select
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "property_select",
                          });
                        }}
                        value={
                          currentProject.name.split("/")[1] +
                          "/" +
                          currentProperty.name.split("/")[1]
                        }
                        className="border border-gray-300 rounded-md p-2 max-w-[250px]"
                        onChange={handleChange}
                      >
                        {currentProject.properties.map((property) => (
                          <option
                            key={property.name}
                            value={
                              currentProject.name.split("/")[1] +
                              "/" +
                              property.name.split("/")[1]
                            }
                          >
                            {property.displayName}
                          </option>
                        ))}
                      </select>
                    </div>*/}
                    </div>
                    <div
                      className="flex gap-2 items-center p-2"
                      id="display-mode"
                    >
                      <span className="text-gray-500 hidden xl:block">
                        Display mode:
                      </span>
                      {buttonColor.map((button, index) => (
                        <button
                          key={index}
                          className={`rounded-xl ${
                            color[0] === button.color[0]
                              ? "border-2 border-black px-2 py-1"
                              : "border-2 border-white hover:border-black px-2 py-1"
                          }`}
                          onClick={() => {
                            pushEventsToDataLayer({
                              event: "display_selected",
                              "data-click-text": button.name?.toLowerCase(),
                            });
                            setColor(button.color);
                          }}
                        >
                          {button.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="text-gray-500 ml-10">
                    <Loader />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </header>
      {box && (
        <div
          className="h-screen w-full bg-transparent absolute top-0 left-0"
          onClick={handleClickOutside}
        >
          <div
            className="w-[80%] xl:w-1/2 bg-white fixed  rounded-[24px] h-[500px] top-2 left-2 
          border border-gray-300 shadow-2xl  z-50 
          "
            onClick={handleClickInside}
          >
            <div className=" flex flex-col ">
              <div className="flex items-center justify-between gap-10  border-b border-gray-300 pb-2 p-4">
                <h5>All</h5>
                <Select
                  value={selectedOption}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setSelectedOption(selectedOption);
                      setCurrentProfile(selectedOption.value.idGoogle);
                      setProfileSelected(selectedOption.value.idGoogle);

                      const selectedProject =
                        projects?.pageCache["page"]?.projects[
                          selectedOption.value.idGoogle
                        ].accounts[0];
                      setAccountSelected(selectedProject);
                      // setCurrentProject(selectedProject);
                      // setCurrentProperty(selectedProject.properties[0]);
                      // setPropertySelected(selectedProject.properties[0]);
                    }
                  }}
                  options={
                    allProfile?.map((profile) => ({
                      value: profile,
                      label: (
                        <div className="flex items-center capitalize">
                          <img
                            src={profile.picture}
                            alt="profile"
                            className="w-6 h-6 rounded-full mr-2"
                            onError={handleImageErrorSelected}
                          />
                          {profile.fullName}
                        </div>
                      ),
                    })) || []
                  }
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                  className="w-[300px]"
                />
                {/* <h5>Favorites</h5> */}
              </div>
              {/* <div className="flex justify-end items-center w-full p-2">
                <div></div>
              </div> */}
              <div className="flex items-start h-full border-t border-gray-300">
                <div
                  id="accounts"
                  className="w-[30%] transition-all duration-300 ease-in-out  hover:transition-all hover:ease-in-out overflow-y-auto h-[390px]"
                  onMouseEnter={() => {
                    const accountsElement =
                      document.getElementById("properties");
                    const propertiesElement =
                      document.getElementById("accounts");

                    if (propertiesElement && accountsElement) {
                      propertiesElement.style.width = "70%";
                      accountsElement.style.width = "30%";
                    }
                  }}
                  onMouseLeave={() => {
                    // Assicurati che gli elementi esistano prima di accedere alle loro proprietà
                    const accountsElement =
                      document.getElementById("properties");
                    const propertiesElement =
                      document.getElementById("accounts");

                    if (propertiesElement && accountsElement) {
                      // add class css to propertiesElement
                      // const divs = propertiesElement.querySelectorAll("span");
                      // divs.forEach((div) => {
                      //   div.classList.add("clamped-text");
                      // });

                      propertiesElement.style.width = "30%";
                      accountsElement.style.width = "70%";
                    }
                  }}
                >
                  <div className="cursor-pointer border-r border-gray-300 p-4 border-b border-gray-300 ">
                    <h5>Accounts</h5>
                  </div>
                  <div className="overflow-y-auto">
                    {projects?.pageCache["page"]?.projects[
                      profileSelected
                    ].accounts.map((project) => (
                      <div
                        className={`cursor-pointer p-4 border-b border-r border-gray-300 clamped-text ${
                          accountSelected.name === project.name
                            ? "bg-gray-200"
                            : "hover:bg-gray-100"
                        }`}
                        key={project.name}
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "account_select",
                          });
                          setAccountSelected(project);
                          setCurrentProject(project);
                          // setCurrentProperty(project.properties[0]);
                          // setPropertySelected(project.properties[0]);

                          // handleChange(project.name.split("/")[1]);
                          // handleClickOutside();
                        }}
                      >
                        {project.displayName}
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  id="properties"
                  className="w-[70%] transition-all duration-300 ease-in-out h-full overflow-y-auto h-[390px]"
                  onMouseEnter={() => {
                    const propertiesElement =
                      document.getElementById("properties");
                    const accountsElement = document.getElementById("accounts");

                    if (propertiesElement && accountsElement) {
                      propertiesElement.style.width = "70%";
                      accountsElement.style.width = "30%";
                    }
                  }}
                  onMouseLeave={() => {
                    // Assicurati che gli elementi esistano prima di accedere alle loro proprietà
                    const propertiesElement =
                      document.getElementById("properties");
                    const accountsElement = document.getElementById("accounts");

                    if (propertiesElement && accountsElement) {
                      propertiesElement.style.width = "30%";
                      accountsElement.style.width = "70%";
                    }
                  }}
                >
                  <div className="cursor-pointer p-4 border-b border-gray-300">
                    <h5>Properties</h5>
                  </div>
                  {/* <select
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "property_select",
                          });
                        }}
                        value={
                          currentProject.name.split("/")[1] +
                          "/" +
                          currentProperty.name.split("/")[1]
                        }
                        className="border border-gray-300 rounded-md p-2 max-w-[250px]"
                        onChange={handleChange}
                      > */}
                  {accountSelected?.properties?.map((property) => (
                    <div
                      key={property.name}
                      onClick={(e) => {
                        const prop = accountSelected.name.split("/")[1];
                        const id = property.name.split("/")[1];
                        const idGoogle = property.idGoogle;
                        pushEventsToDataLayer({
                          event: "property_select",
                        });
                        if (!containsOnlyNumbers(channel)) {
                          navigate(
                            `/${channel}/${idGoogle}/${prop}/${id}?${createParamsSearch(
                              queryParams
                            )}`
                          );
                        } else {
                          navigate(
                            `/audiences-overview/${idGoogle}/${prop}/${id}?${createParamsSearch(
                              queryParams
                            )}`
                          );
                        }
                        handleClickOutside(e);
                      }}
                      className={`cursor-pointer p-4 border-b border-gray-300 clamped-text ${
                        propertySelected.name === property.name
                          ? "bg-gray-200"
                          : "hover:bg-gray-100"
                      }`}
                    >
                      {property.displayName}
                    </div>
                  ))}
                  {/* </select> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
