import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../components/modalError";
import LogoEsteso from "../assets/YourVisualData/logo nero scritta nera estesa.png";
import LogoContratto from "../assets/YourVisualData/logo scritta nera.png";
import SchermataLogin from "../assets/YourVisualData/LoginSchermata-min.png";
import BoxLogin from "../assets/YourVisualData/BoxLogin3.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import pushEventsToDataLayer from "../utils/gtm";
import Logo from "../assets/YourVisualData/favicon.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GoogleAnalyticsProjects = ({ cookies }) => {
  useEffect(() => {
    document.title = "Login | YourVisualData";
  }, []);

  const notify = (message, success) =>
    success
      ? toast.success(message, {
          position: "top-right",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      : toast.error(message, {
          position: "top-right",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

  function isValidEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  async function loginUser() {
    const checkEmail = isValidEmail(user.identifier);
    if (!user.identifier || !checkEmail) {
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        identifier: true,
      }));
      notify("Enter a valid email");
      return;
    }
    if (step === 1) {
      if (!user.password) {
        setErrorInput((prevErrorInput) => ({
          ...prevErrorInput,
          password: true,
        }));
        notify("Enter a password");
        return;
      }
    }

    if (step === 2) {
      if (!user.otp) {
        setErrorInput((prevErrorInput) => ({
          ...prevErrorInput,
          otp: true,
        }));
        notify("Enter an OTP");
        return;
      }
    }
    setDisabledInput(true);
    pushEventsToDataLayer({
      event: "user_login",
    });
    try {
      const res = await fetch(
        process.env.REACT_APP_URL_BACKEND + "auth/local",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            identifier: user.identifier,
            password: user.password,
            otp: user.otp,
          }),
        }
      );
      const data = await res.json();
      if (data?.user && data?.jwt) {
        cookies.set("user", JSON.stringify(data.user));
        const jwtToken = data.jwt;
        cookies.set("jwt", jwtToken);
        navigate("/");
      } else {
        if (data.message) {
          notify(data.message);
          setErrorInput((prevErrorInput) => ({
            ...prevErrorInput,
            identifier: true,
            password: true,
            otp: true,
          }));
          return;
        } else {
          notify("Error during login");
        }
        setErrorInput((prevErrorInput) => ({
          ...prevErrorInput,
          identifier: true,
          password: true,
        }));
      }
    } catch (e) {
      notify("Error during login");
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        identifier: true,
        password: true,
      }));
    } finally {
      setDisabledInput(false);
    }
  }

  const navigate = useNavigate();

  useEffect(() => {
    const token = cookies.get("jwt");
    if (token) {
      window.location.href = "/";
      return;
    }
  }, []);

  const [message, setMessage] = useState("");

  useEffect(() => {
    var url = window.location.search;
    var urlParams = new URLSearchParams(url);
    const code = urlParams.get("emailConfirmed");
    if (code === "true") {
      setMessage("Email confirmed successfully, you can now login");
    } else if (code === "false") {
      setMessage("Email confirmation failed, please try again");
    }
    const firstLogin = urlParams.get("firstLogin");
    if (firstLogin === "true") {
      setMessage("Confirm your email, and login with your credentials");
    }
  }, []);

  const [modalError, setModalError] = useState(false);

  const [isPassword, setIsPassword] = useState(false);

  const [user, setUser] = useState({
    identifier: null,
    password: null,
    otp: null,
  });

  const [disabledInput, setDisabledInput] = useState(false);
  const [errorInput, setErrorInput] = useState({
    identifier: false,
    password: false,
    otp: false,
  });

  function handleInput(event) {
    const { value, name } = event.target;

    setErrorInput((prevErrorInput) => ({
      ...prevErrorInput,
      [name]: false,
    }));

    setUser((prevNewImpianto) => ({
      ...prevNewImpianto,
      [name]: value || null,
    }));

    setDisabledInput(false);
  }

  const [step, setStep] = useState(0);

  async function checkEmailApi() {
    const checkEmail = isValidEmail(user.identifier);
    if (!user.identifier || !checkEmail) {
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        identifier: true,
      }));
      notify("Enter a valid email");
      return;
    }
    setDisabledInput(true);
    try {
      const res = await fetch(
        process.env.REACT_APP_URL_BACKEND +
          "users?identifier=" +
          user.identifier
      );
      const data = await res.json();
      if (data.message === "User found") {
        setStep(1);
        setErrorInput((prevErrorInput) => ({
          ...prevErrorInput,
          identifier: false,
          password: false,
          otp: false,
        }));
      } else {
        if (data.message) {
          notify(data.message);
          return;
        } else {
          notify("Error during login");
        }
        setErrorInput((prevErrorInput) => ({
          ...prevErrorInput,
          identifier: true,
          password: true,
        }));
      }
    } catch (e) {
      notify("Error during login");
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        identifier: true,
        password: true,
      }));
    } finally {
      setDisabledInput(false);
    }
  }
  async function sendOTP() {
    const checkEmail = isValidEmail(user.identifier);
    if (!user.identifier || !checkEmail) {
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        identifier: true,
      }));
      notify("Enter a valid email");
      return;
    }
    setUser((prevNewImpianto) => ({
      ...prevNewImpianto,
      password: "",
      otp: "",
    }));
    setDisabledInput(true);
    try {
      const res = await fetch(
        process.env.REACT_APP_URL_BACKEND + "otp-request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: user.identifier }),
        }
      );
      const data = await res.json();
      if (data.message === "OTP sent") {
        notify("Send OTP on email", true);
        setStep(2);
        setErrorInput((prevErrorInput) => ({
          ...prevErrorInput,
          identifier: false,
          password: false,
          otp: false,
        }));
        setResendTime(60);
        const interval = setInterval(() => {
          setResendTime((prevResendTime) => prevResendTime - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
        }, 60000);
      } else {
        if (data.message) {
          notify(data.message);
          return;
        } else {
          notify("Error on request OTP");
        }
        setErrorInput((prevErrorInput) => ({
          ...prevErrorInput,
          identifier: true,
          password: true,
        }));
      }
    } catch (e) {
      notify("Error on request OTP");
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        identifier: true,
        password: true,
      }));
    } finally {
      setDisabledInput(false);
    }
  }

  const [resendTime, setResendTime] = useState(60);
  return (
    <div className="flex items-center justify-between h-screen flex-row-reverse">
      <div className="w-[60%] h-full md:block hidden">
        <div
          src={SchermataLogin}
          style={{
            backgroundImage: `url(${SchermataLogin})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="w-full h-full object-cover "
        >
          <div className="p-10 xl:p-16 flex items-end gap-2">
            <img src={LogoEsteso} alt="Logo" className=" h-[37px] w-[233px] " />
            <span className="text-[11px] whitespace-nowrap h-[20px]">
              | Powered by Fortop
            </span>
          </div>
          <div className="w-full  flex flex-col p-10 xl:p-16 pt-0 xl:pt-0 2xl:pt-10 justify-center">
            <div className="flex flex-col ">
              <h1 className="xl:text-[64px] text-[40px] text-black font-semibold">
                Your data is
                <span className="font-semibold italic ml-3">safe</span>.
              </h1>
              <div className="mt-2 text-[18px] ">
                <div className="flex flex-col gap-14">
                  <h2 className="text-black font-regular leading-6">
                    With
                    <span className="font-semibold mx-1">Your Visual Data</span>
                    your information <br /> is completely secure. We don't store
                    <br />
                    any property data in our systems.
                  </h2>
                  <h3 className="xl:text-[34px] text-[20px] text-black font-semibold leading-6 lg:leading-10">
                    Three simple steps to achieve your <br />
                    Universal Data Visualization.
                  </h3>
                </div>
                <img className="mt-4" src={BoxLogin} alt="BoxLogin" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2  flex items-center justify-center h-screen relative">
        <div className="w-3/4 p-5 text-center flex items-center justify-center flex-col ">
          <img
            src={Logo}
            alt="Logo YourVisualData"
            className="w-14 h-14 mx-auto mb-10 md:block hidden"
          />
          <div className="flex flex-col  mx-auto mb-10 md:hidden items-end">
            <LazyLoadImage src={LogoContratto} width="200" />
            <span className="text-[10px] whitespace-nowrap mt-[-3px]">
              | Powered by Fortop
            </span>
          </div>
          <h1 className="text-center text-4xl mb-3 font-semibold">Welcome</h1>
          <h2 className="text-[#00000077] font-regular mb-6">{message}</h2>
          <div className="w-full  pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 text-left"
                htmlFor="username"
              >
                Email*
              </label>
              <div className="relative">
                <input
                  id="username"
                  type="text"
                  placeholder="latua@email.com"
                  value={user.identifier}
                  name="identifier"
                  onChange={(e) => handleInput(e)}
                  disabled={disabledInput || step !== 0}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errorInput.identifier ? "border-red-500" : ""
                  }`}
                />
                {step !== 0 && (
                  <div className="absolute right-4 top-2.5 ">
                    <svg
                      onClick={() => {
                        setDisabledInput(false);
                        setStep(0);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 6l7 7l-4 4" />
                      <path d="M5.828 18.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4z" />
                      <path d="M4 20l1.768 -1.768" />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            {step === 0 && (
              <div className="mb-4 w-full">
                <div className="flex items-center justify-between w-full">
                  <button
                    onClick={() => checkEmailApi()}
                    className="bg-[#F9A257] w-full hover:bg-white hover:text-[#F9A257] border border-[#F9A257] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                  >
                    Next step
                  </button>
                </div>
              </div>
            )}
            {step !== 0 && (
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
                  {step === 1 ? "Password*" : "OTP*"}
                </label>
                <div className="relative">
                  <input
                    value={step === 1 ? user.password : user.otp}
                    onChange={(e) => handleInput(e)}
                    type={!isPassword ? "password" : "text"}
                    placeholder={step === 1 ? "Password" : "OTP"}
                    name={step === 1 ? "password" : "otp"}
                    disabled={disabledInput}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      step === 1 && errorInput.password
                        ? "border-red-500"
                        : step === 2 && errorInput.otp
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  <div className="absolute right-4 top-2.5 ">
                    {isPassword ? (
                      <svg
                        onClick={() => setIsPassword(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-eye-off"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                        <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                        <path d="M3 3l18 18" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => setIsPassword(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-eye"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            )}
            {step === 1 && (
              <div className="flex   flex-col w-full">
                <div className="flex items-center justify-between w-full mb-4">
                  <a
                    onClick={() => sendOTP()}
                    className="inline-block align-baseline font-bold text-sm text-black hover:underline hover:text-[#F9A257] cursor-pointer"
                  >
                    Login with OTP via email
                  </a>
                  <a
                    className="inline-block align-baseline font-bold text-sm text-black hover:underline hover:text-[#F9A257]"
                    href="/reset-password"
                  >
                    Forgot Password?
                  </a>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="flex   flex-col w-full">
                <div className="flex items-center justify-between w-full mb-4">
                  <a
                    onClick={() => {
                      setStep(1);
                      setUser((prevNewImpianto) => ({
                        ...prevNewImpianto,
                        password: null,
                        otp: null,
                      }));
                    }}
                    className="inline-block align-baseline font-bold text-sm text-black hover:underline hover:text-[#F9A257] cursor-pointer"
                  >
                    Login with password
                  </a>
                  <a
                    className="inline-block align-baseline font-bold text-sm text-black hover:underline hover:text-[#F9A257]"
                    onClick={() => {
                      if (resendTime === 0) {
                        sendOTP();
                      }
                    }}
                  >
                    {resendTime === 0
                      ? "Resend OTP"
                      : "Resend in " + resendTime}
                  </a>
                </div>
              </div>
            )}
            {step !== 0 && (
              <button
                onClick={() => loginUser()}
                className="bg-[#F9A257] w-full hover:bg-white hover:text-[#F9A257] border border-[#F9A257] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
              >
                Login
              </button>
            )}
            <h4 className="text-sm text-[#00000077] mt-2 block text-left">
              If you don't have an account, you can   
              <a
                href="/signup"
                className="text-black underline"
              > {" "} signup.</a>
             
            </h4>
          </div>
          <div className="bottom-20 absolute w-full text-center">
            <h4 className="text-sm text-[#00000077] mt-2 block text-center">
              By login you accept our <br />
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
                className="text-black underline"
              >
                Privacy Policy
              </a>{" "}
              and our{" "}
              <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noreferrer noopener"
                className="text-black underline"
              >
                Terms and Conditions
              </a>
            </h4>
          </div>
        </div>
      </div>
      <ToastContainer />
      {modalError && (
        <Modal
          modalIsOpen={modalError}
          closeModal={() => setModalError(false)}
          message="Non è stato possibile accedere a Google"
        />
      )}
    </div>
  );
};

export default GoogleAnalyticsProjects;
