import { useEffect, useState } from "react";
// import { useRouter } from "next/router";
// import Link from "next/link";
// import Meta from "../utils/Meta";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

export default function ResetPassword({ cookies }) {
  const [user, setUser] = useState({ email: "" });
  const [errorInput, setErrorInput] = useState({ email: false });

  const [disabledInput, setDisabledInput] = useState(false);

  function validatePasswordB(password) {
    // const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!?@#$%^&*()_+.]).{6,}$/;
    const errors = {};
    let strength = 0;

    if (password.length < 8) {
      errors.lengthError = true;
    } else {
      strength += 1;
    }
    if (/(?=.*[A-Z])/.test(password)) {
      strength += 1;
    } else {
      errors.uppercaseError = true;
    }
    if (/(?=.*\d)/.test(password)) {
      strength += 1;
    } else {
      errors.numberError = true;
    }
    if (/(?=.*[!?@#$%^&*()_+])/.test(password)) {
      strength += 1;
    } else {
      errors.specialCharError = true;
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors: errors,
      strength: strength,
    };
  }

  function handleInput(event) {
    const { value, name } = event.target;

    setErrorInput((prevErrorInput) => ({
      ...prevErrorInput,
      [name]: false,
    }));
    if (name === "password") {
      setIsPassword((prevIsPassword) => ({
        ...prevIsPassword,
        password: value,
      }));
      const validationResult = validatePasswordB(value);
      setIsPasswordValid(validationResult.isValid);
      setPasswordErrors(validationResult.errors);
      setPasswordStrength(validationResult.strength);
    } else {
      setUser((prevNewImpianto) => ({
        ...prevNewImpianto,
        [name]: value || null,
      }));
    }

    setDisabledInput(false);
  }

  function isValidEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  async function forgotPassword() {
    const checkEmail = isValidEmail(user.email);
    if (!user.email || !checkEmail) {
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        email: true,
      }));
      notify("Enter a valid email", false);
      return;
    }
    setDisabledInput(true);
    try {
      const res = await fetch(
        process.env.REACT_APP_URL_BACKEND + "auth/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => data);
      if (res?.ok === true) {
        notify("Email sent successfully", true);
      } else {
        notify("Error sending email", false);
      }
    } catch (e) {
      notify("Error sending email", false);
    } finally {
      setDisabledInput(false);
    }
  }
  const [code, setCode] = useState(null);
  const router = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const token = cookies.get("jwt");
    if (token) {
      navigate("/");
    }
    //check params code
    var url = window.location.search;
    var urlParams = new URLSearchParams(url);
    const code = urlParams.get("code");
    if (code) {
      setCode(code);
    }
  }, [router]);
  const [isPassword, setIsPassword] = useState({
    password: "",
    passwordConfirmation: "",
  });

  function validatePassword(password) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!?@#$%^&*()_+.]).{8,}$/;
    return passwordRegex.test(password);
  }

  async function resetPassword() {
    if (!isPassword.password || !validatePassword(isPassword.password)) {
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        password: true,
      }));
      notify("Enter a valid password", false);
      return;
    }

    setDisabledInput(true);
    try {
      const res = await fetch(
        process.env.REACT_APP_URL_BACKEND + "auth/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: code,
            password: isPassword.password,
            passwordConfirmation: isPassword.password,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => data);
      if (res.user && res.jwt) {
        cookies.remove("user");
        cookies.remove("jwt");
        navigate("/login");
      } else {
        notify("Error, please try to reset your password again!", false);
      }
    } catch (e) {
      notify("Error, please try to reset your password again!", false);
    } finally {
      setDisabledInput(false);
    }
  }

  const notify = (message, success) =>
    success
      ? toast.success(message, {
          position: "top-right",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      : toast.error(message, {
          position: "top-right",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

  function getColor() {
    if (passwordStrength === 0) {
      return "white";
    } else if (passwordStrength === 4) {
      return "green";
    } else if (passwordStrength >= 2) {
      return "yellow";
    } else {
      return "red";
    }
  }

  const [isText, setIsText] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState(0);

  return (
    <div className="w-full h-screen flex md:justify-center items-center flex pt-20 md:pt-8 p-8 lg:flex-row md:flex-col gap-8 flex-col	">
      {/* <Meta
        title="
     Reset Password" */}
      {/* /> */}
      <ToastContainer />
      <div></div>
      <div className="w-full flex items-center md:items-start justify-center">
        <div className="max-w-xl">
          <h1 className="text-4xl font-bold mb-4">Reset Password</h1>
          <ul>
            <li>Forgot your password?</li>
            {!code ? (
              <>
                <li>
                  No worries! Follow the steps below to reset your password.
                </li>
                <li>
                  You will receive an email with instructions to reset your
                  password.
                </li>
                <li>
                  If you don&apos;t have an account,{" "}
                  <a
                    className="underline cursor-pointer hover:no-underline"
                    href="/signup"
                  >
                    sign up
                  </a>
                  , or{" "}
                  <a
                    href="/login"
                    className="underline cursor-pointer hover:no-underline"
                  >
                    login
                  </a>{" "}
                  if you remember your password.
                </li>
              </>
            ) : (
              <>
                <li>Please enter your new password below.</li>
                <li>After resetting, login again with your new password.</li>
                <li>
                  If you remember your password,{" "}
                  <a
                    className="underline cursor-pointer hover:no-underline"
                    href="/login"
                  >
                    login
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      <div className="flex flex-col w-full justify-center items-center">
        <div className="max-w-sm w-full">
          {!code ? (
            <form className="w-full max-w-sm bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  Company Email*
                </label>
                <input
                  id="username"
                  type="text"
                  placeholder="your@email.com"
                  value={user.email}
                  name="email"
                  onChange={(e) => handleInput(e)}
                  disabled={disabledInput}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errorInput.email ? "border-red-500" : ""
                  }`}
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  onClick={() => forgotPassword()}
                  className="bg-[#F9A257] hover:bg-white hover:text-[#F9A257] border border-[#F9A257] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  disabled={disabledInput}
                >
                  Send
                </button>
              </div>
            </form>
          ) : (
            <form className="w-full max-w-sm bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  Password*
                </label>
                <div className="relative">
                  <input
                    id="password"
                    type={isText ? "text" : "password"}
                    placeholder="New Password"
                    value={isPassword.password}
                    name="password"
                    onChange={(e) => handleInput(e)}
                    disabled={disabledInput}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      errorInput.email ? "border-red-500" : ""
                    }`}
                  />
                  <div className="absolute right-4 top-2.5 ">
                    {isText ? (
                      <svg
                        onClick={() => setIsText(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-eye-off"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                        <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                        <path d="M3 3l18 18" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => setIsText(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-eye"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                      </svg>
                    )}
                  </div>
                </div>
                <div
                  className="w-full h-2 mt-2 rounded-full"
                  style={{ backgroundColor: getColor() }}
                ></div>
                {!isPasswordValid && (
                  <div className="text-red-500 text-xs">
                    <p>The password must:</p>
                    <p
                      className={
                        passwordErrors.lengthError
                          ? `text-red-500`
                          : "text-green-500"
                      }
                    >
                      be at least 8 characters long.
                    </p>
                    <p
                      className={
                        passwordErrors.uppercaseError
                          ? `text-red-500`
                          : "text-green-500"
                      }
                    >
                      contain at least one capital letter.
                    </p>
                    <p
                      className={
                        passwordErrors.numberError
                          ? `text-red-500`
                          : "text-green-500"
                      }
                    >
                      contain at least one number.
                    </p>
                    <p
                      className={
                        passwordErrors.specialCharError
                          ? `text-red-500`
                          : "text-green-500"
                      }
                    >
                      contain at least one special character.
                    </p>
                  </div>
                )}{" "}
              </div>
              <div className="flex items-center justify-center">
                <button
                  onClick={() => resetPassword()}
                  className="bg-[#F9A257] hover:bg-white hover:text-[#F9A257] border border-[#F9A257] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  disabled={disabledInput}
                >
                  Send
                </button>
              </div>
            </form>
          )}
          <p className="text-center text-gray-500 text-xs">
            &copy;2024 Fortop All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
