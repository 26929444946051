import { format } from "date-fns";
import { useState } from "react";
import { Collapse } from "react-collapse";

export default function UpdateGoogle({ annotation, isOpenC, setIsOpenC, cookies }) {
  return (
    <table className="w-full border-b border-gray-300 mb-4">
      <caption
        className="text-left text-sm p-4 flex items-center cursor-pointer"
        onClick={() => {setIsOpenC(!isOpenC)
          cookies.set("updateGoogle", !isOpenC)
        }}
      >
        <span>Google Update</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`ml-2 h-4 w-4 transition-transform ${
            isOpenC ? "rotate-180" : ""
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </caption>
      <Collapse isOpened={isOpenC}>
        <thead className="bg-gray-200 w-full">
          <tr className="text-left">
            <th className="p-2 w-1/5">Date Range</th>
            <th className="p-2 w-2/5">Title</th>
            <th className="p-2 w-2/5">Description</th>
          </tr>
        </thead>
        {annotation.map((item, index) => (
          <tr key={index} className="w-full">
            <td className="p-2">
              {format(new Date(item.start), "dd MMM yyyy")} -{" "}
              {format(new Date(item.end), "dd MMM yyyy")}
            </td>
            <td className="p-2">{item.title || " - "}</td>
            <td className="p-2">{item.description || " - "}</td>
          </tr>
        ))}
      </Collapse>
    </table>
  );
}
