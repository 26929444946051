const handleDateChange = (
  names,
  values,
  reset,
  navigate,
  location,
  queryString,
  string
) => {
  const queryParams = queryString.parse(location.search);

  // name and value is a array ["name0", "name1"] and ["value0", "value1"]
  if (names.length !== values.length) {
    console.error("Names and values must have the same length");
    return;
  }

  // Crea un nuovo oggetto di parametri di query
  const newParams = reset ? {} : { ...queryParams };
  // Aggiungi i nuovi parametri
  names.forEach((name, index) => {
    newParams[name] = string
      ? values[index]
      : convertToNumberString(values[index]);
  });
  // remove null from newParams
  Object.keys(newParams).forEach((key) => {
    if (newParams[key] === null) {
      delete newParams[key];
    }
  });
  const newSearchString = queryString.stringify(newParams);
  navigate({
    pathname: location.pathname,
    search: newSearchString,
  });
};

const convertToNumberString = (dateString) => {
  // Verifica se la data è nel formato corretto
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return null;
  }

  // Dividi la stringa in componenti anno, mese e giorno
  const [year, month, day] = dateString.split("-");

  // Crea la stringa nel formato yyyymmdd
  const numberString = `${year}${month}${day}`;

  return numberString;
};
const createParamsSearch = (params) => {
  let search = "";
  for (const key in params) {
    search += key + "=" + params[key] + "&";
  }
  return search;
};

const secondaryDimension = [
  {
    name: "Secondary Dimension",
    type: "null",
  },
  {
    name: "Achievement ID",
    type: "achievementId",
  },
  {
    name: "Ad format",
    type: "adFormat",
  },
  {
    name: "Ad source",
    type: "adSourceName",
  },
  {
    name: "Ad unit",
    type: "adUnitName",
  },
  {
    name: "App version",
    type: "appVersion",
  },
  {
    name: "Audience ID",
    type: "audienceId",
  },
  {
    name: "Audience name",
    type: "audienceName",
  },
  {
    name: "Audience resource name",
    type: "audienceResourceName",
  },
  {
    name: "Interests",
    type: "brandingInterest",
  },
  {
    name: "Browser",
    type: "browser",
  },
  {
    name: "Campaign ID",
    type: "campaignId",
  },
  {
    name: "Campaign",
    type: "campaignName",
  },
  {
    name: "Character",
    type: "character",
  },
  {
    name: "City",
    type: "city",
  },
  {
    name: "City ID",
    type: "cityId",
  },
  {
    name: "CM360 account ID",
    type: "cm360AccountId",
  },
  {
    name: "CM360 account name",
    type: "cm360AccountName",
  },
  {
    name: "CM360 advertiser ID",
    type: "cm360AdvertiserId",
  },
  {
    name: "CM360 advertiser name",
    type: "cm360AdvertiserName",
  },
  {
    name: "CM360 campaign ID",
    type: "cm360CampaignId",
  },
  {
    name: "CM360 campaign name",
    type: "cm360CampaignName",
  },
  {
    name: "CM360 creative format",
    type: "cm360CreativeFormat",
  },
  {
    name: "CM360 creative ID",
    type: "cm360CreativeId",
  },
  {
    name: "CM360 creative name",
    type: "cm360CreativeName",
  },
  {
    name: "CM360 creative type",
    type: "cm360CreativeType",
  },
  {
    name: "CM360 creative type ID",
    type: "cm360CreativeTypeId",
  },
  {
    name: "CM360 creative version",
    type: "cm360CreativeVersion",
  },
  {
    name: "CM360 medium",
    type: "cm360Medium",
  },
  {
    name: "CM360 placement cost structure",
    type: "cm360PlacementCostStructure",
  },
  {
    name: "CM360 placement ID",
    type: "cm360PlacementId",
  },
  {
    name: "CM360 placement name",
    type: "cm360PlacementName",
  },
  {
    name: "CM360 rendering ID",
    type: "cm360RenderingId",
  },
  {
    name: "CM360 site ID",
    type: "cm360SiteId",
  },
  {
    name: "CM360 site name",
    type: "cm360SiteName",
  },
  {
    name: "CM360 source",
    type: "cm360Source",
  },
  {
    name: "CM360 source / medium",
    type: "cm360SourceMedium",
  },
  {
    name: "Cohort",
    type: "cohort",
  },
  {
    name: "Daily cohort",
    type: "cohortNthDay",
  },
  {
    name: "Monthly cohort",
    type: "cohortNthMonth",
  },
  {
    name: "Weekly cohort",
    type: "cohortNthWeek",
  },
  {
    name: "Comparison",
    type: "comparison",
  },
  {
    name: "Content group",
    type: "contentGroup",
  },
  {
    name: "Content ID",
    type: "contentId",
  },
  {
    name: "Content type",
    type: "contentType",
  },
  {
    name: "Continent",
    type: "continent",
  },
  {
    name: "Continent ID",
    type: "continentId",
  },
  {
    name: "Country",
    type: "country",
  },
  {
    name: "Country ID",
    type: "countryId",
  },
  {
    name: "Currency",
    type: "currencyCode",
  },
  {
    name: "Date",
    type: "date",
  },
  {
    name: "Date + hour (YYYYMMDDHH)",
    type: "dateHour",
  },
  {
    name: "Date hour and minute",
    type: "dateHourMinute",
  },
  {
    name: "Day",
    type: "day",
  },
  {
    name: "Day of week",
    type: "dayOfWeek",
  },
  {
    name: "Day of week name",
    type: "dayOfWeekName",
  },
  {
    name: "Default channel group",
    type: "defaultChannelGroup",
  },
  {
    name: "Device category",
    type: "deviceCategory",
  },
  {
    name: "Device model",
    type: "deviceModel",
  },
  {
    name: "DV360 advertiser ID",
    type: "dv360AdvertiserId",
  },
  {
    name: "DV360 advertiser name",
    type: "dv360AdvertiserName",
  },
  {
    name: "DV360 campaign ID",
    type: "dv360CampaignId",
  },
  {
    name: "DV360 campaign name",
    type: "dv360CampaignName",
  },
  {
    name: "DV360 creative format",
    type: "dv360CreativeFormat",
  },
  {
    name: "DV360 creative ID",
    type: "dv360CreativeId",
  },
  {
    name: "DV360 creative name",
    type: "dv360CreativeName",
  },
  {
    name: "DV360 exchange ID",
    type: "dv360ExchangeId",
  },
  {
    name: "DV360 exchange name",
    type: "dv360ExchangeName",
  },
  {
    name: "DV360 insertion order ID",
    type: "dv360InsertionOrderId",
  },
  {
    name: "DV360 insertion order name",
    type: "dv360InsertionOrderName",
  },
  {
    name: "DV360 line item ID",
    type: "dv360LineItemId",
  },
  {
    name: "DV360 line item name",
    type: "dv360LineItemName",
  },
  {
    name: "DV360 medium",
    type: "dv360Medium",
  },
  {
    name: "DV360 partner ID",
    type: "dv360PartnerId",
  },
  {
    name: "DV360 partner name",
    type: "dv360PartnerName",
  },
  {
    name: "DV360 source",
    type: "dv360Source",
  },
  {
    name: "DV360 source / medium",
    type: "dv360SourceMedium",
  },
  {
    name: "Event name",
    type: "eventName",
  },
  {
    name: "File extension",
    type: "fileExtension",
  },
  {
    name: "File name",
    type: "fileName",
  },
  {
    name: "First session date",
    type: "firstSessionDate",
  },
  {
    name: "First user campaign ID",
    type: "firstUserCampaignId",
  },
  {
    name: "First user campaign",
    type: "firstUserCampaignName",
  },
  {
    name: "First user CM360 account ID",
    type: "firstUserCm360AccountId",
  },
  {
    name: "First user CM360 account name",
    type: "firstUserCm360AccountName",
  },
  {
    name: "First user CM360 advertiser ID",
    type: "firstUserCm360AdvertiserId",
  },
  {
    name: "First user CM360 advertiser name",
    type: "firstUserCm360AdvertiserName",
  },
  {
    name: "First user CM360 campaign ID",
    type: "firstUserCm360CampaignId",
  },
  {
    name: "First user CM360 campaign name",
    type: "firstUserCm360CampaignName",
  },
  {
    name: "First user CM360 creative format",
    type: "firstUserCm360CreativeFormat",
  },
  {
    name: "First user CM360 creative ID",
    type: "firstUserCm360CreativeId",
  },
  {
    name: "First user CM360 creative name",
    type: "firstUserCm360CreativeName",
  },
  {
    name: "First user CM360 creative type",
    type: "firstUserCm360CreativeType",
  },
  {
    name: "First user CM360 creative type ID",
    type: "firstUserCm360CreativeTypeId",
  },
  {
    name: "First user CM360 creative version",
    type: "firstUserCm360CreativeVersion",
  },
  {
    name: "First user CM360 medium",
    type: "firstUserCm360Medium",
  },
  {
    name: "First user CM360 placement cost structure",
    type: "firstUserCm360PlacementCostStructure",
  },
  {
    name: "First user CM360 placement ID",
    type: "firstUserCm360PlacementId",
  },
  {
    name: "First user CM360 placement name",
    type: "firstUserCm360PlacementName",
  },
  {
    name: "First user CM360 rendering ID",
    type: "firstUserCm360RenderingId",
  },
  {
    name: "First user CM360 site ID",
    type: "firstUserCm360SiteId",
  },
  {
    name: "First user CM360 site name",
    type: "firstUserCm360SiteName",
  },
  {
    name: "First user CM360 source",
    type: "firstUserCm360Source",
  },
  {
    name: "First user CM360 source / medium",
    type: "firstUserCm360SourceMedium",
  },
  {
    name: "First user default channel group",
    type: "firstUserDefaultChannelGroup",
  },
  {
    name: "First user DV360 advertiser ID",
    type: "firstUserDv360AdvertiserId",
  },
  {
    name: "First user DV360 advertiser name",
    type: "firstUserDv360AdvertiserName",
  },
  {
    name: "First user DV360 campaign ID",
    type: "firstUserDv360CampaignId",
  },
  {
    name: "First user DV360 campaign name",
    type: "firstUserDv360CampaignName",
  },
  {
    name: "First user DV360 creative format",
    type: "firstUserDv360CreativeFormat",
  },
  {
    name: "First user DV360 creative ID",
    type: "firstUserDv360CreativeId",
  },
  {
    name: "First user DV360 creative name",
    type: "firstUserDv360CreativeName",
  },
  {
    name: "First user DV360 exchange ID",
    type: "firstUserDv360ExchangeId",
  },
  {
    name: "First user DV360 exchange name",
    type: "firstUserDv360ExchangeName",
  },
  {
    name: "First user DV360 insertion order ID",
    type: "firstUserDv360InsertionOrderId",
  },
  {
    name: "First user DV360 insertion order name",
    type: "firstUserDv360InsertionOrderName",
  },
  {
    name: "First user DV360 line item ID",
    type: "firstUserDv360LineItemId",
  },
  {
    name: "First user DV360 line item name",
    type: "firstUserDv360LineItemName",
  },
  {
    name: "First user DV360 medium",
    type: "firstUserDv360Medium",
  },
  {
    name: "First user DV360 partner ID",
    type: "firstUserDv360PartnerId",
  },
  {
    name: "First user DV360 partner name",
    type: "firstUserDv360PartnerName",
  },
  {
    name: "First user DV360 source",
    type: "firstUserDv360Source",
  },
  {
    name: "First user DV360 source / medium",
    type: "firstUserDv360SourceMedium",
  },
  {
    name: "First user Google Ads account name",
    type: "firstUserGoogleAdsAccountName",
  },
  {
    name: "First user Google Ads ad group ID",
    type: "firstUserGoogleAdsAdGroupId",
  },
  {
    name: "First user Google Ads ad group name",
    type: "firstUserGoogleAdsAdGroupName",
  },
  {
    name: "First user Google Ads ad network type",
    type: "firstUserGoogleAdsAdNetworkType",
  },
  {
    name: "First user Google Ads campaign ID",
    type: "firstUserGoogleAdsCampaignId",
  },
  {
    name: "First user Google Ads campaign",
    type: "firstUserGoogleAdsCampaignName",
  },
  {
    name: "First user Google Ads campaign type",
    type: "firstUserGoogleAdsCampaignType",
  },
  {
    name: "First user Google Ads creative ID",
    type: "firstUserGoogleAdsCreativeId",
  },
  {
    name: "First user Google Ads customer ID",
    type: "firstUserGoogleAdsCustomerId",
  },
  {
    name: "First user Google Ads keyword text",
    type: "firstUserGoogleAdsKeyword",
  },
  {
    name: "First user Google Ads query",
    type: "firstUserGoogleAdsQuery",
  },
  {
    name: "First user manual ad content",
    type: "firstUserManualAdContent",
  },
  {
    name: "First user manual campaign ID",
    type: "firstUserManualCampaignId",
  },
  {
    name: "First user manual campaign name",
    type: "firstUserManualCampaignName",
  },
  {
    name: "First user manual creative format",
    type: "firstUserManualCreativeFormat",
  },
  {
    name: "First user manual marketing tactic",
    type: "firstUserManualMarketingTactic",
  },
  {
    name: "First user manual medium",
    type: "firstUserManualMedium",
  },
  {
    name: "First user manual source",
    type: "firstUserManualSource",
  },
  {
    name: "First user manual source / medium",
    type: "firstUserManualSourceMedium",
  },
  {
    name: "First user manual source platform",
    type: "firstUserManualSourcePlatform",
  },
  {
    name: "First user manual term",
    type: "firstUserManualTerm",
  },
  {
    name: "First user medium",
    type: "firstUserMedium",
  },
  {
    name: "First user primary channel group",
    type: "firstUserPrimaryChannelGroup",
  },
  {
    name: "First user SA360 ad group ID",
    type: "firstUserSa360AdGroupId",
  },
  {
    name: "First user SA360 ad group name",
    type: "firstUserSa360AdGroupName",
  },
  {
    name: "First user SA360 campaign ID",
    type: "firstUserSa360CampaignId",
  },
  {
    name: "First user SA360 campaign",
    type: "firstUserSa360CampaignName",
  },
  {
    name: "First user SA360 creative format",
    type: "firstUserSa360CreativeFormat",
  },
  {
    name: "First user SA360 engine account ID",
    type: "firstUserSa360EngineAccountId",
  },
  {
    name: "First user SA360 engine account name",
    type: "firstUserSa360EngineAccountName",
  },
  {
    name: "First user SA360 engine account type",
    type: "firstUserSa360EngineAccountType",
  },
  {
    name: "First user SA360 keyword text",
    type: "firstUserSa360KeywordText",
  },
  {
    name: "First user SA360 manager account ID",
    type: "firstUserSa360ManagerAccountId",
  },
  {
    name: "First user SA360 manager account name",
    type: "firstUserSa360ManagerAccountName",
  },
  {
    name: "First user SA360 medium",
    type: "firstUserSa360Medium",
  },
  {
    name: "First user SA360 query",
    type: "firstUserSa360Query",
  },
  {
    name: "First user SA360 source",
    type: "firstUserSa360Source",
  },
  {
    name: "First user SA360 source / medium",
    type: "firstUserSa360SourceMedium",
  },
  {
    name: "First user source",
    type: "firstUserSource",
  },
  {
    name: "First user source / medium",
    type: "firstUserSourceMedium",
  },
  {
    name: "First user source platform",
    type: "firstUserSourcePlatform",
  },
  {
    name: "Full page URL",
    type: "fullPageUrl",
  },
  {
    name: "Google Ads account name",
    type: "googleAdsAccountName",
  },
  {
    name: "Google Ads ad group ID",
    type: "googleAdsAdGroupId",
  },
  {
    name: "Google Ads ad group name",
    type: "googleAdsAdGroupName",
  },
  {
    name: "Google Ads ad network type",
    type: "googleAdsAdNetworkType",
  },
  {
    name: "Google Ads campaign ID",
    type: "googleAdsCampaignId",
  },
  {
    name: "Google Ads campaign",
    type: "googleAdsCampaignName",
  },
  {
    name: "Google Ads campaign type",
    type: "googleAdsCampaignType",
  },
  {
    name: "Google Ads creative ID",
    type: "googleAdsCreativeId",
  },
  {
    name: "Google Ads customer ID",
    type: "googleAdsCustomerId",
  },
  {
    name: "Google Ads keyword text",
    type: "googleAdsKeyword",
  },
  {
    name: "Google Ads query",
    type: "googleAdsQuery",
  },
  {
    name: "Group ID",
    type: "groupId",
  },
  {
    name: "Hostname",
    type: "hostName",
  },
  {
    name: "Hour",
    type: "hour",
  },
  {
    name: "Is key event",
    type: "isKeyEvent",
  },
  {
    name: "ISO week of the year",
    type: "isoWeek",
  },
  {
    name: "ISO year",
    type: "isoYear",
  },
  {
    name: "ISO week of ISO year",
    type: "isoYearIsoWeek",
  },
  {
    name: "Item affiliation",
    type: "itemAffiliation",
  },
  {
    name: "Item brand",
    type: "itemBrand",
  },
  {
    name: "Item category",
    type: "itemCategory",
  },
  {
    name: "Item category 2",
    type: "itemCategory2",
  },
  {
    name: "Item category 3",
    type: "itemCategory3",
  },
  {
    name: "Item category 4",
    type: "itemCategory4",
  },
  {
    name: "Item category 5",
    type: "itemCategory5",
  },
  {
    name: "Item ID",
    type: "itemId",
  },
  {
    name: "Item list ID",
    type: "itemListId",
  },
  {
    name: "Item list name",
    type: "itemListName",
  },
  {
    name: "Item list position",
    type: "itemListPosition",
  },
  {
    name: "Item location ID",
    type: "itemLocationID",
  },
  {
    name: "Item name",
    type: "itemName",
  },
  {
    name: "Item promotion creative name",
    type: "itemPromotionCreativeName",
  },
  {
    name: "Item promotion creative slot",
    type: "itemPromotionCreativeSlot",
  },
  {
    name: "Item promotion ID",
    type: "itemPromotionId",
  },
  {
    name: "Item promotion name",
    type: "itemPromotionName",
  },
  {
    name: "Item variant",
    type: "itemVariant",
  },
  {
    name: "Landing page",
    type: "landingPage",
  },
  {
    name: "Landing page + query string",
    type: "landingPagePlusQueryString",
  },
  {
    name: "Language",
    type: "language",
  },
  {
    name: "Language code",
    type: "languageCode",
  },
  {
    name: "Level",
    type: "level",
  },
  {
    name: "Link classes",
    type: "linkClasses",
  },
  {
    name: "Link domain",
    type: "linkDomain",
  },
  {
    name: "Link ID",
    type: "linkId",
  },
  {
    name: "Link text",
    type: "linkText",
  },
  {
    name: "Link URL",
    type: "linkUrl",
  },
  {
    name: "Manual ad content",
    type: "manualAdContent",
  },
  {
    name: "Manual campaign ID",
    type: "manualCampaignId",
  },
  {
    name: "Manual campaign name",
    type: "manualCampaignName",
  },
  {
    name: "Manual creative format",
    type: "manualCreativeFormat",
  },
  {
    name: "Manual marketing tactic",
    type: "manualMarketingTactic",
  },
  {
    name: "Manual medium",
    type: "manualMedium",
  },
  {
    name: "Manual source",
    type: "manualSource",
  },
  {
    name: "Manual source / medium",
    type: "manualSourceMedium",
  },
  {
    name: "Manual source platform",
    type: "manualSourcePlatform",
  },
  {
    name: "Manual term",
    type: "manualTerm",
  },
  {
    name: "Medium",
    type: "medium",
  },
  {
    name: "Method",
    type: "method",
  },
  {
    name: "Minute",
    type: "minute",
  },
  {
    name: "Device brand",
    type: "mobileDeviceBranding",
  },
  {
    name: "Device",
    type: "mobileDeviceMarketingName",
  },
  {
    name: "Mobile model",
    type: "mobileDeviceModel",
  },
  {
    name: "Month",
    type: "month",
  },
  {
    name: "New / returning",
    type: "newVsReturning",
  },
  {
    name: "Nth day",
    type: "nthDay",
  },
  {
    name: "Nth hour",
    type: "nthHour",
  },
  {
    name: "Nth minute",
    type: "nthMinute",
  },
  {
    name: "Nth month",
    type: "nthMonth",
  },
  {
    name: "Nth week",
    type: "nthWeek",
  },
  {
    name: "Nth year",
    type: "nthYear",
  },
  {
    name: "Operating system",
    type: "operatingSystem",
  },
  {
    name: "OS version",
    type: "operatingSystemVersion",
  },
  {
    name: "Operating system with version",
    type: "operatingSystemWithVersion",
  },
  {
    name: "Order coupon",
    type: "orderCoupon",
  },
  {
    name: "Outbound",
    type: "outbound",
  },
  {
    name: "Page location",
    type: "pageLocation",
  },
  {
    name: "Page path",
    type: "pagePath",
  },
  {
    name: "Page path + query string",
    type: "pagePathPlusQueryString",
  },
  {
    name: "Page referrer",
    type: "pageReferrer",
  },
  {
    name: "Page title",
    type: "pageTitle",
  },
  {
    name: "Percent scrolled",
    type: "percentScrolled",
  },
  {
    name: "Platform",
    type: "platform",
  },
  {
    name: "Platform / device category",
    type: "platformDeviceCategory",
  },
  {
    name: "Primary channel group",
    type: "primaryChannelGroup",
  },
  {
    name: "Region",
    type: "region",
  },
  {
    name: "SA360 ad group ID",
    type: "sa360AdGroupId",
  },
  {
    name: "SA360 ad group name",
    type: "sa360AdGroupName",
  },
  {
    name: "SA360 campaign ID",
    type: "sa360CampaignId",
  },
  {
    name: "SA360 campaign",
    type: "sa360CampaignName",
  },
  {
    name: "SA360 creative format",
    type: "sa360CreativeFormat",
  },
  {
    name: "SA360 engine account ID",
    type: "sa360EngineAccountId",
  },
  {
    name: "SA360 engine account name",
    type: "sa360EngineAccountName",
  },
  {
    name: "SA360 engine account type",
    type: "sa360EngineAccountType",
  },
  {
    name: "SA360 keyword text",
    type: "sa360KeywordText",
  },
  {
    name: "SA360 manager account ID",
    type: "sa360ManagerAccountId",
  },
  {
    name: "SA360 manager account name",
    type: "sa360ManagerAccountName",
  },
  {
    name: "SA360 medium",
    type: "sa360Medium",
  },
  {
    name: "SA360 query",
    type: "sa360Query",
  },
  {
    name: "SA360 source",
    type: "sa360Source",
  },
  {
    name: "SA360 source / medium",
    type: "sa360SourceMedium",
  },
  {
    name: "Screen resolution",
    type: "screenResolution",
  },
  {
    name: "Search term",
    type: "searchTerm",
  },
  {
    name: "Session campaign ID",
    type: "sessionCampaignId",
  },
  {
    name: "Session campaign",
    type: "sessionCampaignName",
  },
  {
    name: "Session CM360 account ID",
    type: "sessionCm360AccountId",
  },
  {
    name: "Session CM360 account name",
    type: "sessionCm360AccountName",
  },
  {
    name: "Session CM360 advertiser ID",
    type: "sessionCm360AdvertiserId",
  },
  {
    name: "Session CM360 advertiser name",
    type: "sessionCm360AdvertiserName",
  },
  {
    name: "Session CM360 campaign ID",
    type: "sessionCm360CampaignId",
  },
  {
    name: "Session CM360 campaign name",
    type: "sessionCm360CampaignName",
  },
  {
    name: "Session CM360 creative format",
    type: "sessionCm360CreativeFormat",
  },
  {
    name: "Session CM360 creative ID",
    type: "sessionCm360CreativeId",
  },
  {
    name: "Session CM360 creative name",
    type: "sessionCm360CreativeName",
  },
  {
    name: "Session CM360 creative type",
    type: "sessionCm360CreativeType",
  },
  {
    name: "Session CM360 creative type ID",
    type: "sessionCm360CreativeTypeId",
  },
  {
    name: "Session CM360 creative version",
    type: "sessionCm360CreativeVersion",
  },
  {
    name: "Session CM360 medium",
    type: "sessionCm360Medium",
  },
  {
    name: "Session CM360 placement cost structure",
    type: "sessionCm360PlacementCostStructure",
  },
  {
    name: "Session CM360 placement ID",
    type: "sessionCm360PlacementId",
  },
  {
    name: "Session CM360 placement name",
    type: "sessionCm360PlacementName",
  },
  {
    name: "Session CM360 rendering ID",
    type: "sessionCm360RenderingId",
  },
  {
    name: "Session CM360 site ID",
    type: "sessionCm360SiteId",
  },
  {
    name: "Session CM360 site name",
    type: "sessionCm360SiteName",
  },
  {
    name: "Session CM360 source",
    type: "sessionCm360Source",
  },
  {
    name: "Session CM360 source / medium",
    type: "sessionCm360SourceMedium",
  },
  {
    name: "Session default channel group",
    type: "sessionDefaultChannelGroup",
  },
  {
    name: "Session DV360 advertiser ID",
    type: "sessionDv360AdvertiserId",
  },
  {
    name: "Session DV360 advertiser name",
    type: "sessionDv360AdvertiserName",
  },
  {
    name: "Session DV360 campaign ID",
    type: "sessionDv360CampaignId",
  },
  {
    name: "Session DV360 campaign name",
    type: "sessionDv360CampaignName",
  },
  {
    name: "Session DV360 creative format",
    type: "sessionDv360CreativeFormat",
  },
  {
    name: "Session DV360 creative ID",
    type: "sessionDv360CreativeId",
  },
  {
    name: "Session DV360 creative name",
    type: "sessionDv360CreativeName",
  },
  {
    name: "Session DV360 exchange ID",
    type: "sessionDv360ExchangeId",
  },
  {
    name: "Session DV360 exchange name",
    type: "sessionDv360ExchangeName",
  },
  {
    name: "Session DV360 insertion order ID",
    type: "sessionDv360InsertionOrderId",
  },
  {
    name: "Session DV360 insertion order name",
    type: "sessionDv360InsertionOrderName",
  },
  {
    name: "Session DV360 line item ID",
    type: "sessionDv360LineItemId",
  },
  {
    name: "Session DV360 line item name",
    type: "sessionDv360LineItemName",
  },
  {
    name: "Session DV360 medium",
    type: "sessionDv360Medium",
  },
  {
    name: "Session DV360 partner ID",
    type: "sessionDv360PartnerId",
  },
  {
    name: "Session DV360 partner name",
    type: "sessionDv360PartnerName",
  },
  {
    name: "Session DV360 source",
    type: "sessionDv360Source",
  },
  {
    name: "Session DV360 source / medium",
    type: "sessionDv360SourceMedium",
  },
  {
    name: "Session Google Ads account name",
    type: "sessionGoogleAdsAccountName",
  },
  {
    name: "Session Google Ads ad group ID",
    type: "sessionGoogleAdsAdGroupId",
  },
  {
    name: "Session Google Ads ad group name",
    type: "sessionGoogleAdsAdGroupName",
  },
  {
    name: "Session Google Ads ad network type",
    type: "sessionGoogleAdsAdNetworkType",
  },
  {
    name: "Session Google Ads campaign ID",
    type: "sessionGoogleAdsCampaignId",
  },
  {
    name: "Session Google Ads campaign",
    type: "sessionGoogleAdsCampaignName",
  },
  {
    name: "Session Google Ads campaign type",
    type: "sessionGoogleAdsCampaignType",
  },
  {
    name: "Session Google Ads creative ID",
    type: "sessionGoogleAdsCreativeId",
  },
  {
    name: "Session Google Ads customer ID",
    type: "sessionGoogleAdsCustomerId",
  },
  {
    name: "Session Google Ads keyword text",
    type: "sessionGoogleAdsKeyword",
  },
  {
    name: "Session Google Ads query",
    type: "sessionGoogleAdsQuery",
  },
  {
    name: "Session manual ad content",
    type: "sessionManualAdContent",
  },
  {
    name: "Session manual campaign ID",
    type: "sessionManualCampaignId",
  },
  {
    name: "Session manual campaign name",
    type: "sessionManualCampaignName",
  },
  {
    name: "Session manual creative format",
    type: "sessionManualCreativeFormat",
  },
  {
    name: "Session manual marketing tactic",
    type: "sessionManualMarketingTactic",
  },
  {
    name: "Session manual medium",
    type: "sessionManualMedium",
  },
  {
    name: "Session manual source",
    type: "sessionManualSource",
  },
  {
    name: "Session manual source / medium",
    type: "sessionManualSourceMedium",
  },
  {
    name: "Session manual source platform",
    type: "sessionManualSourcePlatform",
  },
  {
    name: "Session manual term",
    type: "sessionManualTerm",
  },
  {
    name: "Session medium",
    type: "sessionMedium",
  },
  {
    name: "Session primary channel group",
    type: "sessionPrimaryChannelGroup",
  },
  {
    name: "Session SA360 ad group ID",
    type: "sessionSa360AdGroupId",
  },
  {
    name: "Session SA360 ad group name",
    type: "sessionSa360AdGroupName",
  },
  {
    name: "Session SA360 campaign ID",
    type: "sessionSa360CampaignId",
  },
  {
    name: "Session SA360 campaign",
    type: "sessionSa360CampaignName",
  },
  {
    name: "Session SA360 creative format",
    type: "sessionSa360CreativeFormat",
  },
  {
    name: "Session SA360 engine account ID",
    type: "sessionSa360EngineAccountId",
  },
  {
    name: "Session SA360 engine account name",
    type: "sessionSa360EngineAccountName",
  },
  {
    name: "Session SA360 engine account type",
    type: "sessionSa360EngineAccountType",
  },
  {
    name: "Session SA360 keyword text",
    type: "sessionSa360Keyword",
  },
  {
    name: "Session SA360 manager account ID",
    type: "sessionSa360ManagerAccountId",
  },
  {
    name: "Session SA360 manager account name",
    type: "sessionSa360ManagerAccountName",
  },
  {
    name: "Session SA360 medium",
    type: "sessionSa360Medium",
  },
  {
    name: "Session SA360 query",
    type: "sessionSa360Query",
  },
  {
    name: "Session SA360 source",
    type: "sessionSa360Source",
  },
  {
    name: "Session SA360 source / medium",
    type: "sessionSa360SourceMedium",
  },
  {
    name: "Session source",
    type: "sessionSource",
  },
  {
    name: "Session source / medium",
    type: "sessionSourceMedium",
  },
  {
    name: "Session source platform",
    type: "sessionSourcePlatform",
  },
  {
    name: "Shipping tier",
    type: "shippingTier",
  },
  {
    name: "Signed in with user ID",
    type: "signedInWithUserId",
  },
  {
    name: "Source",
    type: "source",
  },
  {
    name: "Source / medium",
    type: "sourceMedium",
  },
  {
    name: "Source platform",
    type: "sourcePlatform",
  },
  {
    name: "Stream ID",
    type: "streamId",
  },
  {
    name: "Stream name",
    type: "streamName",
  },
  {
    name: "Test data filter ID",
    type: "testDataFilterId",
  },
  {
    name: "Test data filter name",
    type: "testDataFilterName",
  },
  {
    name: "Transaction ID",
    type: "transactionId",
  },
  {
    name: "Page path and screen class",
    type: "unifiedPagePathScreen",
  },
  {
    name: "Page path + query string and screen class",
    type: "unifiedPageScreen",
  },
  {
    name: "Page title and screen class",
    type: "unifiedScreenClass",
  },
  {
    name: "Page title and screen name",
    type: "unifiedScreenName",
  },
  {
    name: "Age",
    type: "userAgeBracket",
  },
  {
    name: "Gender",
    type: "userGender",
  },
  {
    name: "Video provider",
    type: "videoProvider",
  },
  {
    name: "Video title",
    type: "videoTitle",
  },
  {
    name: "Video URL",
    type: "videoUrl",
  },
  {
    name: "Virtual currency name",
    type: "virtualCurrencyName",
  },
  {
    name: "Visible",
    type: "visible",
  },
  {
    name: "Week",
    type: "week",
  },
  {
    name: "Year",
    type: "year",
  },
  {
    name: "Year month",
    type: "yearMonth",
  },
  {
    name: "Year week",
    type: "yearWeek",
  },
];

export { handleDateChange, createParamsSearch, secondaryDimension };
