// import 'animate.css';
import "./index.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "shepherd.js/dist/css/shepherd.css";
import React, { useEffect, useId, useState } from "react";
import { RecoilRoot } from "recoil";
import Cookies from "universal-cookie";
import ModalError from "./components/modalError";

import Header from "./components/header";
import Sidebar from "./components/sidebar";

import CookieDeclaration from "./utils/CookieDeclaration";

import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/Sign-up";
import ConfirmEmail from "./pages/ConfirmEmail";
import Auth from "./pages/Auth";

import Profile from "./pages/Profile";

import Properties from "./pages/Properties";
import Overview from "./pages/Overview";
import Events from "./pages/Events";
import Age from "./pages/Age";
import Country from "./pages/Country";
import Region from "./pages/Region";
import City from "./pages/City";
import Interest from "./pages/Interest";
import Language from "./pages/Language";
import Gender from "./pages/Gender";
import Channels from "./pages/Channels";
import LandingPage from "./pages/LandingPage";
import LandingPageFiltered from "./pages/LandingPageFiltered";
import AllPages from "./pages/AllPages";
import Form from "./pages/Form";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TerminiECondizioni from "./pages/TerminiECondizioni";

import ResetPassword from "./pages/ResetPassword";
import NotFoundPage from "./pages/NotFound";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import PopoverProfile from "./components/popverProfile";
import PopverHeader from "./components/popverHeader";

import { ShepherdTour, TourMethods } from "react-shepherd";
import steps from "./utils/steps";
import Start from "./utils/start";

const WithNav = ({
  cookies,
  openPopup,
  setOpenPopup,
  setOpenModal,
  openModal,
  color,
  setColor,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(100),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const headingId = useId();
  const [profile, setProfile] = useState(false);

  return (
    <>
      <Header
        cookies={cookies}
        setProfile={setProfile}
        profile={profile}
        getReferenceProps={getReferenceProps}
        context={context}
        reference={reference}
        setOpenModal={setOpenModal}
        openModal={openModal}
        color={color}
        setColor={setColor}
      />
      <PopoverProfile
        profile={profile}
        y={y}
        floating={floating}
        strategy={strategy}
        context={context}
        headingId={headingId}
        getFloatingProps={getFloatingProps}
        reference={reference}
        open={open}
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        cookies={cookies}
      />
      <div className="flex overflow-hidden w-full">
        <Sidebar
          setProfile={setProfile}
          getReferenceProps={getReferenceProps}
          context={context}
          reference={reference}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          cookies={cookies}
        />
      </div>
      <Outlet />
    </>
  );
};
const RouterManager = ({ cookies, larghezzaPagina }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [openPopup2, setOpenPopup2] = useState(false);

  const [openModal2, setOpenModal2] = useState(false);
  const [color, setColor] = useState(
    cookies.get("color") || ["#058dc7", "#ed7e17", "#c6e9f9", "#ed7e1733"]
  );

  return (
    <React.Suspense>
      <div className="min-h-screen overflow-hidden bg-gray cursor-default">
        <Routes>
          {larghezzaPagina >= 750 && (
            <>
              <Route
                element={
                  <WithNav
                    cookies={cookies}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    color={color}
                    setColor={setColor}
                  />
                }
              >
                <Route
                  path="/"
                  element={
                    <Home cookies={cookies} larghezzaPagina={larghezzaPagina} />
                  }
                />
                <Route
                  path="/:properties"
                  element={<Home cookies={cookies} />}
                />
                <Route
                  path="/:properties/:project"
                  element={<Home cookies={cookies} color={color} />}
                />
                <Route
                  path="/:profile/:properties/:project"
                  element={<Home cookies={cookies} color={color} />}
                />
                <Route
                  path="/audiences-overview/:profile/:properties/:project"
                  element={<Overview cookies={cookies} color={color} />}
                />
                <Route
                  path="/acquisition-channels/:profile/:properties/:project"
                  element={<Channels cookies={cookies} color={color} />}
                />
                <Route
                  path="/engagement-events/:profile/:properties/:project"
                  element={<Events cookies={cookies} color={color} />}
                />

                {/* Demographics */}
                <Route
                  path="/audiences-demographics-country/:profile/:properties/:project"
                  element={<Country cookies={cookies} color={color} />}
                />
                 <Route
                  path="/audiences-demographics-region/:profile/:properties/:project"
                  element={<Region cookies={cookies} color={color} />}
                />
                 <Route
                  path="/audiences-demographics-city/:profile/:properties/:project"
                  element={<City cookies={cookies} color={color} />}
                />
                 <Route
                  path="/audiences-demographics-language/:profile/:properties/:project"
                  element={<Language cookies={cookies} color={color} />}
                />
                <Route
                  path="/audiences-demographics-age/:profile/:properties/:project"
                  element={<Age cookies={cookies} color={color} />}
                />
                <Route
                  path="/audiences-demographics-gender/:profile/:properties/:project"
                  element={<Gender cookies={cookies} color={color} />}
                />
                 <Route
                  path="/audiences-demographics-interests/:profile/:properties/:project"
                  element={<Interest cookies={cookies} color={color} />}
                />
                {/* Demographics */}
                <Route
                  path="/content-landing-pages/:profile/:properties/:project"
                  element={<LandingPage cookies={cookies} color={color} />}
                />
                 <Route
                  path="/content-landing-pages-filtered/:profile/:properties/:project"
                  element={<LandingPageFiltered cookies={cookies} color={color} />}
                />
                 <Route
                  path="/content-all-pages/:profile/:properties/:project"
                  element={<AllPages cookies={cookies} color={color} />}
                />
                
              </Route>
              <Route path="/form" element={<Form cookies={cookies} />} />
              <Route path="/profile" element={<Profile cookies={cookies} />} />
            </>
          )}
          <Route path="login" element={<Login cookies={cookies} />} />
          <Route path="signup" element={<SignUp cookies={cookies} />} />
          <Route
            path="confirm-email"
            element={<ConfirmEmail cookies={cookies} />}
          />
          <Route
            path="oauth2callback"
            element={
              <Auth cookies={cookies} larghezzaPagina={larghezzaPagina} />
            }
          />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="terms-and-conditions"
            element={<TerminiECondizioni cookies={cookies} />}
          />
          <Route
            path="reset-password"
            element={<ResetPassword cookies={cookies} />}
          />
          <Route path="/cookie-policy" element={<CookieDeclaration />} />
          <Route
            path="*"
            element={<NotFoundPage width={larghezzaPagina} cookies={cookies} />}
          />
        </Routes>

        {openModal && (
          <ModalError
            modalIsOpen={openModal}
            closeModal={() => setOpenModal(false)}
            message="Rimuovi l'accesso all'applicazione One Click"
            remove={true}
          />
        )}
      </div>
    </React.Suspense>
  );
};

export const AppMain = () => {
  const [larghezzaPagina, setLarghezzaPagina] = useState(750);

  useEffect(() => {
    const larghezzaBody = document.body.offsetWidth;
    setLarghezzaPagina(larghezzaBody);

    const handleResize = () => {
      const larghezzaBody = document.body.offsetWidth;
      setLarghezzaPagina(larghezzaBody);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const tourOptions = {
    defaultStepOptions: { showCancelLink: true },
    useModalOverlay: true,
    keyboardNavigation: false,
  };

  const cookies = new Cookies(null, { path: "/" });
  return (
    <BrowserRouter>
      <RecoilRoot>
        {larghezzaPagina >= 750 && (
          <ShepherdTour steps={steps} tourOptions={tourOptions}>
            <TourMethods>
              {(tourContext) => (
                <Start startTour={tourContext} cookies={cookies} />
              )}
            </TourMethods>
          </ShepherdTour>
        )}
        <RouterManager cookies={cookies} larghezzaPagina={larghezzaPagina} />
      </RecoilRoot>
    </BrowserRouter>
  );
};
