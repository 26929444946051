import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ButtonSidebar from "../components/buttonSidebar";
import homeN from "../assets/homen.svg";
import channelN from "../assets/channelN.svg";
import landingPageN from "../assets/landingPageN.svg";
import imgprofilo from "../assets/imgprofilo.png";
import queryString from "query-string";
import { createParamsSearch } from "../utils/params";
import { Collapse } from "react-collapse";
import { set } from "date-fns";
import noPic from "../assets/noPic.webp";


function Sidebar({ sidebarOpen, setSidebarOpen, cookies }) {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { pathname } = location;
  let page = pathname.split("/")[1];
  const trigger = useRef(null);
  const sidebar = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current?.contains(target) ||
        trigger.current?.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });
  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const router = useParams();

  const [image, setImage] = useState("");
  useEffect(() => {
    const user_ = cookies.get("user");
    if (user_) {
      setImage(process.env.REACT_APP_URL_BACKEND +
        user_?.immagine?.url);
    }
  }, [router]);

  const handleImageError = () => {
    setImage(noPic);
  };

  const button = [
    {
      name: "Audience",
      img: landingPageN,
      path: "audiences",
      items: [
        {
          name: "overview",
          path: "audiences-overview",
        },
        {
          name: "demographics",
          buttons: [
            {
              name: "Language",
              path: "audiences-demographics-language",
            },
            {
              name: "Age",
              path: "audiences-demographics-age",
            },
            {
              name: "Gender",
              path: "audiences-demographics-gender",
            },
            {
              name: "Interests",
              path: "audiences-demographics-interests",
            },
          ],
        },
        {
          name: "location",
          buttons: [
            {
              name: "Country",
              path: "audiences-demographics-country",
            },
            {
              name: "Region",
              path: "audiences-demographics-region",
            },
            {
              name: "City",
              path: "audiences-demographics-city",
            }
          ],
        },
      ],
    },
    {
      name: "Acquisition",
      img: homeN,
      path: "acquisition",
      items: [
        {
          name: "All Traffic",
          buttons: [
            {
              name: "Channels",
              path: "acquisition-channels",
            },
          ],
        },
      ],
    },
    {
      name: "Behavior",
      img: channelN,
      path: "behavior",
      items: [
        {
          name: "Engagement",
          buttons: [
            {
              name: "Events",
              path: "engagement-events",
            },
          ],
        },
        {
          name: "Site Content",
          buttons: [
            {
              name: "All Pages",
              path: "content-all-pages",
            },
            {
              name: "Landing Pages",
              path: "content-landing-pages",
            },
          ],
        },
      ],
    },
  ];

  const [isOpenC, setIsOpenC] = useState(-1);
  const [isOpen, setIsOpen] = useState(-1);
  return (
    <div
      className={
        sidebarOpen
          ? "w-[150px] z-20 fixed block pt-[60px] bg-white"
          : "w-[66px] z-20 fixed block pt-[60px] bg-white"
      }
    >
      <div
        style={{
          borderTopWidth: "0px",
          borderLeftWidth: "0px",
          borderBottomWidth: "0px",
        }}
        onMouseEnter={() => {
          setSidebarOpen(true);
          setIsOpenC(-1);
          setIsOpen(-1);
        }}
        onMouseLeave={() => {
          setSidebarOpen(false);
          setIsOpenC(-1);
          setIsOpen(-1);
        }}
        id="sidebar"
        ref={sidebar}
        className="border-r border-solid border-lightgray absolute z-20 left-auto top-0 translate-x-0 hover:transition-all transform h-screen overflow-x-hidden overflow-y-auto border-solid  no-scrollbar w-[66px] transition  ease-in-out transition-transform hover:w-[250px] flex-shrink-0 bg-white p-[10px] relative"
      >
        <div className="relative">
          <ul>
            {button.map((item, index) => (
              <li key={index}>
                <ButtonSidebar
                  page={page}
                  sidebarOpen={sidebarOpen}
                  name={item.name}
                  imgNera={item.img}
                  setSidebarOpen={setSidebarOpen}
                  pathname={pathname}
                  selected={page.startsWith(item.path)}
                  openCollapse={() => {
                    if (isOpenC !== index) {
                      setIsOpenC(index);
                    } else {
                      setIsOpenC(-1);
                    }
                  }}
                />
                {item.items.map((button, indexB) => (
                  <Collapse key={indexB} isOpened={isOpenC === index}>
                    <div className="flex flex-col gap-2 px-4">
                      {button.buttons ? (
                        <div className="p-2 ">
                          <div className="flex items-center w-full cursor-pointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#000"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className={isOpen === indexB ? "rotate-90" : ""}
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M9 6l6 6l-6 6" />
                            </svg>
                            <span
                              className="capitalize "
                              onClick={() => {
                                if (isOpen !== indexB) {
                                  setIsOpen(indexB);
                                } else {
                                  setIsOpen(-1);
                                }
                              }}
                            >
                              {button.name}
                            </span>
                          </div>
                          <Collapse key={indexB} isOpened={isOpen === indexB}>
                            <div className="flex flex-col gap-2 mt-4 px-4 pb-4">
                              {button.buttons.map((subButton, subIndex) => (
                                <NavLink
                                  key={subIndex}
                                  to={
                                    subButton.path +
                                    "/" +
                                    router.profile +
                                    "/" +
                                    router.properties +
                                    "/" +
                                    router.project +
                                    "?" +
                                    createParamsSearch(queryParams)
                                  }
                                  onClick={() => {
                                    setIsOpenC(-1);
                                  }}
                                  className=""
                                >
                                  {subButton.name}
                                </NavLink>
                              ))}
                            </div>
                          </Collapse>
                        </div>
                      ) : (
                        <NavLink
                          key={indexB}
                          to={
                            button.path +
                            "/" +
                            router.profile +
                            "/" +
                            router.properties +
                            "/" +
                            router.project +
                            "?" +
                            createParamsSearch(queryParams)
                          }
                          onClick={() => {
                            setIsOpenC(-1);
                            setIsOpen(-1);
                          }}
                          className="p-2 capitalize"
                        >
                          {button.name}
                        </NavLink>
                      )}
                    </div>
                  </Collapse>
                ))}
              </li>
            ))}
          </ul>
        </div>
        <div
          className={
            sidebarOpen
              ? "absolute bottom-28 w-[84%]"
              : "absolute bottom-28 w-min"
          }
        >
          <div className="mx-center">
            <ul>
              <NavLink to={"/profile"}>
                <li
                  className={`transition ease-in-out px-[13px] py-[13px] w-full rounded-[12px] mt-[12px] cursor-pointer ${
                    page === "profile" && "bg-gray-300"
                  } ${
                    sidebarOpen && page !== "profile" && "hover:bg-gray-100"
                  }   
                `}
                  // className={`transition ease-in-out px-[13px] py-[13px] w-full rounded-[12px] mt-[12px] cursor-pointer`}
                >
                  <span
                    className={
                      sidebarOpen
                        ? "w-full flex items-center "
                        : "w-[20px] h-[20px]  flex items-center"
                    }
                  >
                    {image && (
                      <img
                        src={image}
                        alt="profile"
                        className="w-[20px] h-[20px] rounded-full"
                        onError={handleImageError}
                      />
                    )}
                    <span className="ml-[5px] w-full flex items-center justify-between">
                      {sidebarOpen && <h4>Profile</h4>}
                    </span>
                  </span>
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;