import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Link from "next/link";
// import { useRouter } from 'next/router';
// import Meta from "../utils/Meta";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Home({ cookies }) {
  const notify = (message, success) =>
    success
      ? toast.success(message, {
          position: "top-right",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      : toast.error(message, {
          position: "top-right",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

  function isValidEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    username: "",
  });

  function validatePassword(password) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!?@#$%^&*()_+.]).{8,}$/;
    return passwordRegex.test(password);
  }

  function validatePasswordB(password) {
    // const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!?@#$%^&*()_+.]).{6,}$/;
    const errors = {};
    let strength = 0;

    if (password.length < 8) {
      errors.lengthError = true;
    } else {
      strength += 1;
    }
    if (/(?=.*[A-Z])/.test(password)) {
      strength += 1;
    } else {
      errors.uppercaseError = true;
    }
    if (/(?=.*\d)/.test(password)) {
      strength += 1;
    } else {
      errors.numberError = true;
    }
    if (/(?=.*[!?@#$%^&*()_+])/.test(password)) {
      strength += 1;
    } else {
      errors.specialCharError = true;
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors: errors,
      strength: strength,
    };
  }

  const [errorInput, setErrorInput] = useState({
    email: false,
    password: false,
  });

  const navigate = useNavigate()

  // create user signup
  async function createUser() {
    const regex = /^(?=.*[^\s]).+$/;
    if (!newUser.nome || !regex.test(newUser.nome)) {
      selectInput("nome");
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        nome: true,
      }));
      notify("Enter your name.", false);
      return;
    }
    if (!newUser.cognome || !regex.test(newUser.cognome)) {
      selectInput("cognome");
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        cognome: true,
      }));
      notify("Enter your last name.", false);
      return;
    }
    if (!newUser.email || !isValidEmail(newUser.email)) {
      selectInput("username");
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        email: true,
      }));
      notify("Enter a valid email.", false);
      return;
    }
    // if (!isEmailAziendale(newUser.email)) {
    //   selectInput("username");
    //   setErrorInput((prevErrorInput) => ({
    //     ...prevErrorInput,
    //     email: true
    //   }));
    //   notify("Inserire una email aziendale valida.", false);
    //   return
    // }
    const checkPassword = validatePassword(newUser.password);
    if (!newUser.password || !checkPassword) {
      selectInput("password");
      setErrorInput((prevErrorInput) => ({
        ...prevErrorInput,
        password: true,
      }));
      notify("Please enter a valid password.", false);
      return;
    }
    setDisabledInput(true);
    try {
      const res = await fetch(
        process.env.REACT_APP_URL_BACKEND + "auth/local/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: newUser.email,
            email: newUser.email,
            password: newUser.password,
            given_name: newUser.nome,
            family_name: newUser.cognome,
            // azienda: newUser.azienda,
            // ruolo_azienda: newUser.ruolo_azienda
          }),
        }
      );
      const data = await res.json();
      if (data && data.user) {
        notify("User created successfully", true);
        cookies.set("user", JSON.stringify(data.user));
        setDisabledInput(false);
        // redirect to dashboard
        setTimeout(() => {
          navigate("/login?firstLogin=true");
        }, 1000);
      } else {
        if (data.error) {
          if (data.error.message === "Email or Username are already taken") {
            notify(data.error.message, false);
            setDisabledInput(false);
            return;
          }
        }
        setDisabledInput(false);
        notify("Error creating user.", false);
      }
    } catch (e) {
      notify("Error creating user.", false);
      setDisabledInput(false);
    }
  }

  function selectInput(id) {
    const input = document.getElementById(id);
    input?.select();
  }

  // const router = useRouter();
  // useEffect(() => {
  //   const token = localStorage.getItem("jwt")
  //   if (token) {
  //     // Se il token esiste, reindirizza l'utente alla pagina dei dashboard
  //     router.push('/dashboards');
  //     return
  //   }
  // }, [router])

  function handleInput(event) {
    const { value, name } = event.target;

    setErrorInput((prevErrorInput) => ({
      ...prevErrorInput,
      [name]: false,
    }));

    setNewUser((prevNewUser) => ({
      ...prevNewUser,
      [name]: value,
    }));
    if (name === "email") {
      newUser.username = value;
    }
    if (name === "password") {
      const validationResult = validatePasswordB(value);
      setIsPasswordValid(validationResult.isValid);
      setPasswordErrors(validationResult.errors);
      setPasswordStrength(validationResult.strength);
    }

    setDisabledInput(false);
  }

  function getColor() {
    if (passwordStrength === 0) {
      return "white";
    } else if (passwordStrength === 4) {
      return "green";
    } else if (passwordStrength >= 2) {
      return "yellow";
    } else {
      return "red";
    }
  }

  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState(0);

  const [disabledInput, setDisabledInput] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  return (
    <>
      <ToastContainer />
      <div className="w-full min-h-[calc(100vh-90px)] flex md:justify-center items-center mt-20 md:mt-[90px] flex  p-8 lg:flex-row md:flex-col gap-8 flex-col	">
        {/* <Meta
          title="
 Sign Up"
        /> */}
        <div className="w-full flex items-center md:items-start justify-center">
          <div className="max-w-xl w-full">
            <h1 className="text-4xl font-bold mb-4">Sign Up</h1>
            <ul>
              <li>Welcome!</li>
              <li>
                Sign up and wait for your dashboards to be assigned to you.
              </li>
              <li>
                If you already have an account,{" "}
                <a
                  href="/login"
                  className="underline cursor-pointer hover:no-underline"
                >
                  log in.
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col w-full justify-center items-center">
          <div className="max-w-xl w-full">
            <form className="w-full  bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="flex gap-4">
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="nome"
                  >
                    Name*
                  </label>
                  <input
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      errorInput.nome ? "border-red-500" : ""
                    }`}
                    id="nome"
                    type="text"
                    placeholder="Your name"
                    value={newUser.nome}
                    name="nome"
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    disabled={disabledInput}
                  />
                </div>
                <div className="mb-4 w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="cognome"
                  >
                    Surname*
                  </label>
                  <input
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      errorInput.cognome ? "border-red-500" : ""
                    }`}
                    id="cognome"
                    type="text"
                    placeholder="Your surname"
                    value={newUser.cognome}
                    name="cognome"
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    disabled={disabledInput}
                  />
                </div>
              </div>
              <div className="mb-4 w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  Email*
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errorInput.email ? "border-red-500" : ""
                  }`}
                  id="username"
                  type="text"
                  placeholder="your@email.com"
                  value={newUser.email}
                  name="email"
                  onChange={(e) => {
                    handleInput(e);
                  }}
                  disabled={disabledInput}
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password*
                </label>
                <div className="relative">
                  <input
                    value={newUser.password}
                    name="password"
                    onChange={(e) => handleInput(e)}
                    disabled={disabledInput}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      errorInput.password ? "border-red-500" : ""
                    }`}
                    type={isPassword ? "text" : "password"}
                    placeholder="••••••••"
                  />
                  <div className="absolute right-4 top-2.5 ">
                    {isPassword ? (
                      <svg
                        onClick={() => setIsPassword(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-eye-off"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                        <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                        <path d="M3 3l18 18" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => setIsPassword(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-eye"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                      </svg>
                    )}
                  </div>
                </div>
                <div
                  className="w-full h-2 mt-2 rounded-full"
                  style={{ backgroundColor: getColor() }}
                ></div>
                {!isPasswordValid && (
                  <div className="text-red-500 text-xs">
                    <p>The password must:</p>
                    <p
                      className={
                        passwordErrors.lengthError
                          ? `text-red-500`
                          : "text-green-500"
                      }
                    >
                      be at least 8 characters long.
                    </p>
                    <p
                      className={
                        passwordErrors.uppercaseError
                          ? `text-red-500`
                          : "text-green-500"
                      }
                    >
                      contain at least one capital letter.
                    </p>
                    <p
                      className={
                        passwordErrors.numberError
                          ? `text-red-500`
                          : "text-green-500"
                      }
                    >
                      contain at least one number.
                    </p>
                    <p
                      className={
                        passwordErrors.specialCharError
                          ? `text-red-500`
                          : "text-green-500"
                      }
                    >
                      contain at least one special character.
                    </p>
                  </div>
                )}
              </div>
              <div className="md:flex md:items-center">
                <div className="md:w-1/3"></div>
                <div className="md:w-2/3">
                  <button
                    onClick={() => createUser()}
                    className="bg-[#F9A257] hover:bg-white hover:text-[#F9A257] border border-[#F9A257] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </form>
            <p className="text-center text-gray-500 text-xs">
              &copy;2024 Fortop All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
