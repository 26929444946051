import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import { projectsState, LogoutCache } from "../utils/atom/projects";
import { instagramCheck } from "../utils/atom/instagram";
import { useRecoilState } from "recoil";

const Home = ({ cookies, larghezzaPagina }) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useRecoilState(projectsState);
  const [loading, setLoading] = useState(true);

  const [message, setMessage] = useState("");
  useEffect(() => {
    if (projects && projects?.pageCache?.page?.projects?.length === 0) {
      setMessage(
        "You don't have any analytics project, if you think it's a mistake, contact the support"
      );
      setLoading(false);
    }
    if (projects && projects.error) {
      if (projects.message === "Insufficient permissions") {
        setMessage(
          "Insufficient permissions. Please log out and log in again with the necessary permissions."
        );
      }
      if (projects.message === "No capabilities") {
        setStep(1);
      }
      setLoading(false);
    }
  }, [projects]);
  const [step, setStep] = useState(0);

  const logout = async () => {
    LogoutCache(setProjects, cookies);
    cookies.remove("user");
    cookies.remove("jwt");
    cookies.remove("color");
    cookies.remove("positions");
    cookies.remove("Wizard");
    cookies.remove("Wizard_welcome");
    navigate("/login");
  };

  const LoginFunction = async () => {
    // Ottieni l'URL dall'operazione asincrona
    const url = await AuthLocal();
    setClick(true);
    // Apri la pagina con il ritardo minimo
    openPage(url);
  };


  const openPage = (url) => {
    // Usa setTimeout per garantire che la finestra venga aperta in modo sincrono
    setTimeout(() => {
      if (url) {
        window.open(url, "_blank");
      } else {
        console.error("URL non valido.");
      }
    }, 0); // Ritardo minimo
  };

  const AuthLocal = async () => {

    const url = process.env.REACT_APP_URL_BACKEND + "auth-google";

    try {
      const token = cookies.get("jwt");
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();

      if (data.client && data.client.url) {
        return data.client.url;
      }

      return null; // Nessun URL trovato
    } catch (error) {
      console.error("Error fetching data:", error);
      return null; // Gestisci l'errore, nessun URL
    }
  };
  const [click, setClick] = useState(false);

  useEffect(() => {
    let intervalId;
      intervalId = setInterval(() => {
        const refresh = cookies.get("refresh");
        if (click && refresh) {
          navigate("/")
        }
      }, 3000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [click]);

  return (
    <div className="h-screen bg-white flex justify-center items-center p-20">
      {loading ? (
        <Loader />
      ) : step === 0 ? (
        <div className="text-xl w-[450px] text-center">
          {message}
          <br />
          <a
            href="mailto:info@yourvisualdata.com"
            className="text-blue-500 hover:underline"
          >
            info@yourvisualdata.com
          </a>
          <br />
          {message.startsWith("Insufficient permissions") && (
            <button
              onClick={logout}
              className="mt-4  rounded-md p-2
            bg-gray-200
            hover:bg-gray-300"
            >
              Logout
            </button>
          )}
        </div>
      ) : (
        <div className="text-xl w-1/2 text-center bg-gray-100  rounded-xl shadow-lg p-6 flex items-center justify-center flex-col">
          <h2 className="text-2xl font-bold mb-4">
            Access Your Analytics Data
          </h2>
          <p className="mb-4">
            To view your personalized analytics data, please sign in with your
            Google account. This will allow us to securely retrieve and display
            your data, giving you valuable insights into your performance.
          </p>
          <button
            className="w-80 flex items-center justify-center p-3 bg-white text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100
            font-semibold text-lg
            "
            onClick={() => LoginFunction()}
          >
            <img
              src="https://developers.google.com/identity/images/g-logo.png"
              alt="Google Logo"
              className="w-6 h-6 mr-2"
            />
            Login In with Google
          </button>
        </div>
      )}
    </div>
  );
};

export default Home;
