import Modal from "react-modal";
import { useEffect } from "react";
export default function ModaleDelete(props) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px 20px 20px 20px",
      maxHeight: "90vh",
      overflow: "hidden",
    },
    overlay: {
      background: "rgba(54, 55, 79, 0.87)",
    },
  };

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <div className="w-[500px] h-[400px] overflow-y-auto">
        <div className="">
          <div className="mx-auto  text-center w-[504px] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 absolute">
            {/* <div className='text-center'>
                            <LazyLoadImage alt="" src={upgradeAllert} className='w-[81px] ' />
                        </div> */}
            <h2 className="mt-4 text-xl">
              Do you want to delete your account?
            </h2>
            <div className="flex justify-center items-center mt-2">
              <h4 className="text-sm w-1/2">
                By deleting your account, you will lose all associated
                dashboards.
              </h4>
            </div>
            <div className="flex gap-4 w-full items-center justify-center mt-8">
              <button
                onClick={props.closeModal}
                disabled={props.disabledButton}
                className="bg-[#49D42E] hover:bg-white hover:text-[#49D42E] border border-[#49D42E] text-white font-bold px-5 py-2.5 text-sm w-full sm:w-auto rounded focus:outline-none focus:shadow-outline"
              >
                Return to Profile
              </button>
              <button
                onClick={props.deleteUser}
                disabled={props.disabledButton}
                className="bg-red-600 hover:bg-red-300 border border-red-600 hover:border-red-300 text-white font-bold px-5 py-2.5 text-sm w-full sm:w-auto rounded focus:outline-none focus:shadow-outline"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
